/* eslint-disable import/no-unresolved */
import { ReactElement } from 'react';
import { Typography } from '../../Components/Typography/Typography';

/**
 * 404 page
 *
 * @returns ReactElement
 */
export const NotFound = (): ReactElement => {

  return (
    <div className="error-page">
      <Typography content="404 Page Not Found" usage="header" />
    </div>
  );
};

export const InvalidRegistration = (): ReactElement => (
  <div className="error-page">
    <Typography content="Invalid registration information" usage="header" />
  </div>
)

export const LinkUsed = (): ReactElement => (
  <div className="mx-auto w-6/12 text-center">
    <Typography content="Signup Link Used" usage="header" />
    <br />
    <Typography content="An account has already been created with this link. Please log in." usage="subheader" />
    <a href='/login'>Login</a>
  </div>
)
