import { Admin } from '../Admin'
import { useState, ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Typography } from '../../../Components/Typography/Typography';
import { NetworkHelper } from '../../../helpers/networkHelper';
import { useSelector } from 'react-redux';
import { getUser } from '../../../slices/userSlice';
import { Loader } from '../../../Components/Loader/Loader';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const User = (user: any): ReactElement => {
  const [loading, setLoading] = useState(false);
  let { uid } = useParams();
  const { token } = useSelector(getUser);
  let [userData, setUserData] = useState<{ [key: string]: any }>({});
  let [suspendOpen, setSuspendOpen] = useState(false)
  let [resetOpen, setResetOpen] = useState(false)
  let [sentrilockOpen, setSentrilockOpen] = useState(false)

  useEffect(() => {
    async function retrieveData() {
      if (uid === undefined) {
        return;
      }
      await setLoading(true);
      const result = await NetworkHelper.getUser(token, uid);
      await setUserData(result);
      await setLoading(false);
    }
    retrieveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submit() {
    setLoading(true);
    if (uid === undefined) {
      return;
    }
    await NetworkHelper.suspendUser(token, uid);
    setUserData({ ...userData, status: 'Suspended' });
    setSuspendOpen(false)
    setLoading(false);
  }

  async function resetSubmit() {
    setLoading(true);
    if (uid === undefined) {
      return;
    }
    await NetworkHelper.resetUser(token, uid);
    setUserData({ ...userData, status: 'Unregistered' });
    setResetOpen(false)
    setLoading(false);
  }

  async function sentrilockConfirmation() {
    setLoading(true);
    if (uid === undefined) {
      return;
    }
    await NetworkHelper.confirmSentrilockAccess(token, uid);
    const result = await NetworkHelper.getUser(token, uid);
    setUserData(result);
    setSentrilockOpen(false)
    setLoading(false);
  }

  const { name, username, agency_name, email, phone_number, reco_id, brokerage_name, broker_record_name, broker_email, status, sentrilock_confirmed_by, sentrilock_confirmed_at } = userData

  return (
    <div id='admin-user-page'>
      <Admin >
        <Loader show={loading} hideText={true} />
        <Typography typeClass={['text-left']} content="User information" usage="header" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <p><b>User Details</b></p>
          <p></p>
          <p data-testid="name">Name: {name}</p>
          <p>Username: {username}</p>
          <p>Agency Name: {agency_name}</p>
          <p>Email: {email}</p>
          <p>Phone: {phone_number}</p>
          <p>RECO ID: {reco_id}</p>
          <p>Status: {status}</p>
        </div>
        <br></br>
        <div>
          <p><b>Broker Details</b></p>
          <p>Brokerage Name: {brokerage_name}</p>
          <p>Broker of Record Name: {broker_record_name}</p>
          <p>Broker of Record Email: {broker_email}</p>
        </div>
        <br></br>
        <div>
          <p><b>SentriLock Details</b></p>
          <p>Status: {sentrilock_confirmed_at ? 'Admin Approved' : 'Needs Admin Approval'}</p>
          {sentrilock_confirmed_at && (
            <>
              <p>Confirmed By: {sentrilock_confirmed_by}</p>
              <p>Confirmed At: {sentrilock_confirmed_at}</p>
            </>
          )}
        </div>
        <div>
          {status === 'SentriLock Pending' && <button className='btn btn-primary mr-5' onClick={() => { setSentrilockOpen(true) }} type='button'>Confirm SentriLock Access</button>}
          <button className='btn btn-primary mr-5' onClick={() => { setSuspendOpen(true) }} type='button'>Suspend account</button>
          <button className='btn btn-primary' onClick={() => { setResetOpen(true) }} type='button'>Reset account</button>
        </div>
      </Admin>
      <UserDialog
        title='Are you sure you want to suspend this account?'
        message='Suspending the user will halt their subscription, block SentriLock access, and prevent registering again. The user will be notified of the suspension.'
        cancelAction={() => { setSuspendOpen(false) }}
        confirmAction={submit}
        open={suspendOpen} />
      <UserDialog
        title='Are you sure you want to reset this account?'
        message='Resetting the user will halt their subscription, block SentriLock access, and require them to complete the registration and approval process again.'
        cancelAction={() => { setResetOpen(false) }}
        confirmAction={resetSubmit}
        open={resetOpen} />
      <UserDialog
        title='Are you sure you want to confirm this user has been given SentriLock credentials?'
        message='This confirmation will be recorded for future reference'
        cancelAction={() => { setSentrilockOpen(false) }}
        confirmAction={sentrilockConfirmation}
        open={sentrilockOpen} />

    </div>
  )
}

function UserDialog({ title, message, cancelAction, confirmAction, open }: { title: string, message: string, cancelAction: () => void, confirmAction: () => void, open: boolean }) {
  return (<Dialog
    open={open}
    onClose={cancelAction}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancelAction}>Cancel</Button>
      <Button onClick={confirmAction} autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>)
}