import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../slices/dataSlice';
import userReducer from '../slices/userSlice';
import navigationReducer from '../slices/navigationSlice';

export type StoreType = {
  data: {};
  user: {};
  navigation: {};
};

export default configureStore({
  reducer: {
    data: dataReducer,
    user: userReducer,
    navigation: navigationReducer
  }
});
