import React, { useState } from 'react';

import { DataGrid, GridColDef, GridSortDirection, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';

import './DataTable.scss'

export interface DataTableItem {
  [key: string]: string
  id: string
  name: string
  email: string
  memberBoard: string
  brokerage: string
  status: string
  createdAt: string
}

export interface SortingPreferences {
  field: string
  sort: string
}

const CustomToolbar = ({ setFilterButtonEl }: { setFilterButtonEl: any }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} onResize={undefined} onResizeCapture={undefined} />
  </GridToolbarContainer>
);

interface DataTableProps {
  data: DataTableItem[]
  columns: GridColDef[]
  sortField?: string
  sortDirection?: GridSortDirection
  onRowClick?: (rowData: { [key: string]: any }) => void
}

export const DataTable = ({
  data,
  columns,
  sortField,
  sortDirection,
  onRowClick,
  ...props
}: DataTableProps) => {
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  let initialState = {};

  

  if (sortField && sortDirection) {
    initialState = {
      sorting: {
        sortModel: [{ field: sortField, sort: sortDirection }],
      },
    }
  }
  return (
    <>
      <div className='data-table'>
        
        <DataGrid autoHeight={true} rows={data} columns={columns} 
        sx={{
          '.data': {
            bgcolor: "white"
          }
        }}
          getRowClassName={() => 'data'}
          initialState={initialState}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            panel: {
              anchorEl: filterButtonEl,
            },
            toolbar: {
              setFilterButtonEl,
            },
          }}
          onRowClick={(rowData) => {
            if (onRowClick) onRowClick(rowData)
          }} />
      </div>
    </>
  );
};
