import { Admin } from '../Admin'
import * as React from 'react'
import { useState } from 'react'
import { DataTable, DataTableItem } from '../../../Components/DataTable/DataTable'
import { GridColDef } from '@mui/x-data-grid';
import { NetworkHelper } from '../../../helpers/networkHelper';
import { useSelector } from 'react-redux';
import { getUser } from '../../../slices/userSlice';
import { Loader } from '../../../Components/Loader/Loader';

export const Admins = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  let [rows, setRows] = useState<DataTableItem[]>([]);

  const { token } = useSelector(getUser);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Realtor Name', width: 200 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'status', headerName: 'Status', width: 200 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
  ];

  function rowClick(rowData: { [key: string]: any }) {
    // Do nothing
  }

  React.useEffect(() => {
    async function populateTable() {
      await setLoading(true);
      const userList = await NetworkHelper.getAdminList(token);
      const tableList: DataTableItem[] = userList.map(convertData);
      await setRows(tableList);
      await setLoading(false);
    }

    function convertData(data: any) {
      let item: DataTableItem = {
        brokerage: data.brokerageName,
        id: data.id,
        email: data.email,
        name: data.name,
        username: data.username,
        memberBoard: data.agency_name,
        status: data.account_status,
        createdAt: data.created_at
      };

      return item;
    }

    populateTable();
  }, [token]);

  return (
    <Admin>
      <Loader show={loading} hideText={true} />
      <DataTable data={rows} columns={columns} sortField='createdAt' sortDirection='desc' onRowClick={rowClick} />
    </Admin>
  )
}
