import { Dashboard, AdminSignUp, Admins, User, Csv } from './pages/Admin';
import { Login, Registration, SignUp, Confirmation, Landing, NotFound, InvalidRegistration, LinkUsed, Payment, Payments, BrokerActivate, BrokerActivateComplete, ForgotPassword, BrokerExpired } from './pages';
//import { Dashboard, Second } from 'pages/Admin';
//import { Login, Registration, SignUp, Confirmation, Landing, NotFound, InvalidRegistration, Payment } from 'pages';
import { useSelector } from 'react-redux';
import { getUser } from './slices/userSlice';

import {
  Routes as Switch,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";
import { UserPage } from './Components/UserPage/UserPage';

const Routes = function () {
  return (
    <Switch>
      <Route path="/" element={<Outlet />}>
        {/* Public routes */}

        <Route path="login" element={<UserPage><Login /></UserPage>} />
        <Route path="forgot" element={<UserPage><ForgotPassword /></UserPage>} />
        <Route path="signup" element={<UserPage><SignUp /></UserPage>} />
        <Route path="signup/:encName/:encEmail/:encUID/:encBrokerage/:encPass/:paramIV/:passIV" element={<UserPage><SignUp /></UserPage>} />
        <Route path="404" element={<UserPage><NotFound /></UserPage>} />
        <Route path="invalid" element={<UserPage><InvalidRegistration /></ UserPage>} />
        <Route path="link-used" element={<UserPage><LinkUsed /></ UserPage>} />
        <Route path="payments/updateMethod/:sessionID" element={<UserPage><Payments /></UserPage>} />
        <Route path="expired" element={<UserPage><BrokerExpired /></UserPage>} />

        <Route path="activate/:encToken/:encUserID/:encPass/:paramIV" element={<UserPage><BrokerActivate /></UserPage>} />
        <Route path="activate/complete" element={<UserPage><BrokerActivateComplete /></UserPage>} />

        {/* Authorized routes */}
        <Route index element={<AuthorizedRoute><UserPage><Landing /></UserPage></AuthorizedRoute>} />
        <Route path="register" element={<AuthorizedRoute><UserPage><Registration /></UserPage></AuthorizedRoute>} />
        <Route path="register/confirmation" element={<AuthorizedRoute><UserPage><Confirmation /></UserPage></AuthorizedRoute>} />
        <Route path="payment" element={<AuthorizedRoute><UserPage><Payment /></UserPage></AuthorizedRoute>} />
        <Route path="payments" element={<AuthorizedRoute><UserPage><Payments /></UserPage></AuthorizedRoute>} />

        {/* Admin only routes */}
        <Route path="admin/" element={<AdminRoute><Outlet /></AdminRoute>}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="signup" element={<AdminSignUp />} />
          <Route path="admins" element={<Admins />} />
          <Route path="user/:uid" element={<User />} />
          <Route path="csv" element={<Csv />} />
        </Route>

        <Route path='kwar-lockbock-access-agreement.html'></Route>
        <Route path='kwar-grant-of-permission.html'></Route>
        <Route path='KWAR - Grant of Permission Agreement (Brokerage).pdf'></Route>
        <Route path='WRAR - Grant of Permission Agreement (Brokerage).pdf'></Route>
        <Route path='KWAR - Lockbox Access Agreement with Realtors.pdf'></Route>
        <Route path='WRAR - Lockbox Access Agreement with Realtors.pdf'></Route>

        {/* 
          Redirecting changed in react-router v6
          Background: https://gist.github.com/mjackson/b5748add2795ce7448a366ae8f8ae3bb
        */}
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
    </Switch>
  );
};

/**
 * Routes to /admin/* pages
 * 
 * @param param0 
 * @returns 
 */
const AdminRoute = ({ children }: any) => {
  const user = useSelector(getUser);

  // if not logged in or logged in but not admin redirect
  if (!user.token || user.group !== 'Admin') {
    return <Navigate replace to="/login" />;
  }
  return children;
}

/**
 * Routes to non admin authorized pages
 * 
 * @param param0 
 * @returns 
 */
function AuthorizedRoute({ children }: any) {
  const user = useSelector(getUser);

  // redirect if not logged in
  if (!user.token) {
    return <Navigate replace to="/login" />
  }

  return children;

}

export default Routes;
