/* eslint-disable import/no-unresolved */
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setFieldWithID } from '../../../slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../../../Components/Typography/Typography';
import { Form } from '../../../Components/Form/Form';
import { InputProps } from '../../../Components/Input/Input';
import { validationSchemas } from '../../../constants/validation';
import Auth from '@aws-amplify/auth';
import awsconfig from '../../../aws-exports';
import { getData, setDataFieldWithID } from '../../../slices/dataSlice';
import { NetworkHelper } from '../../../helpers/networkHelper';
import { Admin } from '../Admin'

export const AdminSignUp = (): ReactElement => {
  const localUser = useSelector(getUser);
  const { data } = useSelector(getData);
  const [formError, setFormError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const formatPhoneNumber = (phone: string) => {
    let phoneNumber = phone.replace('+1', '') // remove country identifier if it exists
    phoneNumber = phoneNumber.replace(/[^\d]/g, ''); // ensure no other non-digit values
    dispatch(setDataFieldWithID({ id: 'phone', value: `+1${phoneNumber}` }));
    return `+1${phoneNumber}`;
  }

  const resetErrorMessages = () => {
    setFormError('');
    setEmailError('');
    setUsernameError('');
    setPhoneError('');
    setPasswordError('');
    setConfirmError('');
  }

  const onSubmit = async (formObj: any) => {
    const validator = validationSchemas['signup'];
    let phoneValue = data['phone'] || '';
    const passwordValue = data['signupPassword'] || '';
    const usernameValue = data['username'] || '';

    const { error } = validator.validate(formObj, {
      abortEarly: false
    });

    if (error) {
      resetErrorMessages();
      for (const row of error.details) {
        if (row.path[0] === 'email') {
          setEmailError(row.message);
        }
        if (row.path[0] === 'username') {
          setUsernameError(row.message);
        }
        if (row.path[0] === 'phone') {
          setPhoneError(row.message);
        }
        if (row.path[0] === 'signupPassword') {
          setPasswordError(row.message);
        }
        if (row.path[0] === 'confirmPassword') {
          setConfirmError(row.message);
        }
      }
      return false; // prevent form submission
    } else {
      resetErrorMessages();
      phoneValue = formatPhoneNumber(phoneValue);

      Auth.configure(awsconfig);
      try {
        const user = await Auth.signUp({
          username: usernameValue,
          password: passwordValue,
          attributes: {
            email: data.email,
            name: data.name,
            phone_number: phoneValue
            // other custom attributes i.e. trreb id
          }
        });
        const result = await NetworkHelper.verifyAdminRegistration(localUser.token, user.user.getUsername(), user.userSub, data.name, phoneValue, data.email, data.agencyName);

        if (result === undefined) {
          throw new Error('Failed to create account');
        }

        dispatch(setFieldWithID({ id: 'status', value: 'unregistered' }));
        navigate('/admin/dashboard', { state: `${data.name} successfully created an account` });
        alert("Account created successfully");
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case 'UsernameExistsException':
              setUsernameError('Login ID is taken.');
              break;
            case 'InvalidPasswordException':
            case 'InvalidParameterException':
              setFormError('The password must be at least 8 characters.');
              break;
            default:
              setFormError('Something went wrong. Unable to create account at this time.');
          }
        }
        return false;
      }

      return true;
    }
  }

  const inputFields: InputProps[] = [
    {
      type: 'text',
      id: 'name',
      label: 'Name',
      required: true,
      // readOnly: true,
      // value: data.name ? data.name : ''
    },
    {
      type: 'email',
      id: 'email',
      label: 'Email',
      required: true,
      errorText: emailError
    },
    {
      type: 'text',
      id: 'username',
      label: 'Login ID',
      required: true,
      errorText: usernameError,
    },
    {
      type: 'text',
      id: 'phone',
      label: 'Phone Number',
      required: true,
      placeholder: 'e.g. 555-555-5555',
      hint: 'This field is required for multifactor authentication.',
      maxLength: 16,
      errorText: phoneError
    },
    {
      type: 'password',
      id: 'signupPassword',
      label: 'Password',
      required: true,
      hint: "Password must contain at least 8 characters.",
      errorText: passwordError
    },
    {
      type: 'password',
      id: 'confirmPassword',
      label: 'Confirm Password',
      required: true,
      errorText: confirmError
    }
  ];

  return (
    <div id="admin-sign-up-page">
      <Admin >
        <div className='mx-auto w-100 md:w-3/4 lg:w-1/2 px-4'>
          <Typography typeClass={['text-center']} content="Create New Admin" usage="header" />
          <Form hideCancel inputFields={inputFields} errorMessage={formError} valuesOnSubmit onSubmit={onSubmit} ></Form>
        </div>
      </Admin>
    </div>
  );
}