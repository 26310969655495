import React from 'react';
// import { Wizard } from './Components/Wizard/Wizard';
import Routes from './Routes';
import {
  BrowserRouter as Router
} from "react-router-dom";

const App = function () {
  return (
    <Router>
      <div className="App">
        <Routes/>
      </div>
    </Router>
  );
};

export default App;
