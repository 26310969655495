import './Login.scss';
import { useState, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getData, clearData } from '../../slices/dataSlice';
import { setFieldWithID } from '../../slices/userSlice';
import { Button } from '../Button/Button';
import { Input, InputProps } from '../Input/Input';
import { Form } from '../Form/Form';
import Auth from '@aws-amplify/auth';
import awsconfig from './../../aws-exports';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../Typography/Typography';
import Alert from "@mui/material/Alert";

export const LoginForm = function (props: any) {
  const data = useSelector(getData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usernameValue = data.data['username'] || '';
  const passwordValue = data.data['password'] || '';
  const [errorMessage, setErrorMessage] = useState("");
  const [displayState, setDisplayState] = useState('login');
  const [cognitoUser, setCognitoUser] = useState({});
  const [resendResult, setResendtResult] = useState<any>();

  const resendCode = async () => {
    try {
      setCognitoUser(await Auth.signIn(usernameValue, passwordValue));
      setResendtResult("Successfully sent new code.");
    } catch (error) {
      setResendtResult(new Error("Something went wrong, could not send code."));
    }
  }

  // Function to handle authentication of login form with cognito using amplify
  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    Auth.configure(awsconfig);
    e.preventDefault();
    try {
      // Authenticate user using cognito
      setCognitoUser(await Auth.signIn(usernameValue, passwordValue))

      // Removes error message if there was an authentication error 
      setErrorMessage("");
      // Removes error state surrounding input fields if there was an authenication error
      // Show mfa code input and hide login
      switchForm();
    } catch (error) {
      //console.log(error);
      // Sets error message and updates input fields to error when authentication fails
      setErrorMessage("Incorrect username or password. Please try again.");
    }
  }

  const submitCode = async (formObj: any) => {
    try {
      // Attempt to confirm code
      await Auth.confirmSignIn(cognitoUser, formObj.code);
      // Remove error message if there is one
      setErrorMessage("");
      console.log("Sign in confirmed");

      // Get current session
      const data = await Auth.currentSession();
      const token = data.getAccessToken().getJwtToken();
      dispatch(setFieldWithID({ id: 'token', value: token }));
      dispatch(clearData());
      const payload = data.getAccessToken().decodePayload()
      const groupsArray: string[] = payload['cognito:groups']
      //Redirect user based on group
      if (groupsArray.includes('Admin')) {
        navigate('/admin/dashboard', { replace: true });
      } else if (groupsArray.includes('User')) {
        navigate('/', { replace: true });
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'ExpiredCodeException':
            setErrorMessage('Code has expired. Please try again.');
            break;
          case 'CodeMismatchException':
            setErrorMessage('Incorrect code. Please try again.');
            break;
          case 'TooManyFailedAttemptsException':
            setErrorMessage('Too many failed attempts. Please try again later.');
            break;
          default:
            setErrorMessage('Something went wrong. Please try again');
        }
        if (formObj.code.length !== 6) {
          setErrorMessage('Code must be 6 digits long.');
        }
      }
    }
  };

  const inputFields: InputProps[] = [
    {
      type: 'text',
      id: 'code',
      label: 'Multifactor Authentication Code',
      required: true, // change to true for when mfa is enabled
      errorText: errorMessage
    }
  ];

  const switchForm = () => {
    setDisplayState('mfa');
  };

  function renderForm(): ReactElement {
    switch (displayState) {
      case 'login':
        return <LoginFormContents submitForm={submitForm} errorMessage={errorMessage} inputWrapperClass={errorMessage.length > 0 ? ['errorWrapper'] : ['login-input', 'mb-4']} newUserMessage={props.state} />;
      case 'mfa':
        return <MFADisplay inputFields={inputFields} submitCode={submitCode} resendCode={resendCode} resendResult={resendResult} />;
      default:
        return <></>;
    }
  }

  return renderForm();
};


function LoginFormContents({ submitForm, inputWrapperClass, errorMessage, newUserMessage }: {
  submitForm: (e: React.FormEvent<HTMLFormElement>) => void,
  inputWrapperClass: string[],
  errorMessage?: string,
  newUserMessage: string
}) {
  return (
    <div>
      <Typography typeClass={['text-center']} content={newUserMessage} usage="subheader" />
      <Typography typeClass={['text-center']} content="OTERR Login" usage="header" />
      <p>Welcome to OTERR, the <b>O</b>ut of <b>T</b>own <b>E</b>lectronic <b>R</b>EALTOR® <b>R</b>egistration service.</p>
      <p>Please login using your homeboard MLS® ID, and the password you created when you signed up for this application.</p>
      <form id="login-form" className="flex-auto px-12 py-6" onSubmit={submitForm}>
        <Input type="text" id="username" label="Login ID or Username" wrapperClass={inputWrapperClass} />
        <Input type="password" id="password" label="Password" errorText={errorMessage} wrapperClass={inputWrapperClass} />
        <Button type="submit" label="Login" primary buttonClass={["login-button", "btn", "btn-primary", "w-full", "my-4"]} />
      </form>
      <a href="/forgot" className="block text-center text-sm">Forgotten Password?</a>
    </div>
  )
}

function MFADisplay({ inputFields, submitCode, resendCode, resendResult }: { inputFields: InputProps[], submitCode: (e: React.FormEvent<HTMLFormElement>) => void, resendCode: () => void, resendResult: any }) {
  return (
    <>
      <Typography typeClass={['text-center']} content="Authenticate Your Account" usage="header" />
      <div className="mfa-form">
        <p>
          Please confirm your access by entering the authorization code that was sent to your mobile phone number. It may take a couple minutes to recieve your code. Please note that the code is only valid for 3 minutes.
        </p>
        <Form inputFields={inputFields} valuesOnSubmit onSubmit={submitCode} hideCancel={true} ></Form>
      </div>
      {
        resendResult ?
          <Alert className="my-4" severity={resendResult.error ? 'error' : 'success'}>{resendResult.error ? resendResult.error.message : 'Successfully sent new code.'}</Alert> : <></>
      }
      Haven't received it? <span className="text-kwarred cursor-pointer" onClick={resendCode}>Resend a new code.</span>
    </>
  )
}