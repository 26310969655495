import React from 'react';
import facebookLogo from '../../img/facebook-square.svg';
import linkedInLogo from '../../img/linkedin-square.svg';
import instagramLogo from '../../img/instagram-square.svg';
import twitterLogo from '../../img/twitter-square.svg';
// import youtubeLogo from '../../img/youtube.svg';

import { Anchor } from '../Anchor/Anchor';

export const Social = function () {
    return (
        <div className="social">
            <span className='text-blue-darkest'>{process.env.REACT_APP_ENVIRONMENT_LABEL}</span>

            <Anchor
                href='https://www.facebook.com/waterlooregionrealtors/'
                src={facebookLogo}
                anchorClass={['socialLink']}
                imgClass={['socialGlyph']}
            />
            <Anchor
                href='https://www.instagram.com/wr.realtors/'
                src={instagramLogo}
                anchorClass={['socialLink']}
                imgClass={['socialGlyph']}
            />
            <Anchor
                href='https://twitter.com/wr_realtors'
                src={twitterLogo}
                anchorClass={['socialLink']}
                imgClass={['socialGlyph']}
            />
            <Anchor
                href='https://www.linkedin.com/company/waterlooregionrealtors/'
                src={linkedInLogo}
                anchorClass={['socialLink']}
                imgClass={['socialGlyph']}
            />
            {/* <Anchor
                href='https://www.youtube.com/user/KWREALTORS'
                src={youtubeLogo}
                anchorClass={['socialLink']}
                imgClass={['socialGlyph']}
            /> */}
            <p className="md:mt-8"><a href="https://wrar.ca/privacy-policy" className="text-white">Privacy Policy</a></p>
        </div>
    );
};
