/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect } from 'react';
import { LoginForm } from '../../Components/Login/Login';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import awsconfig from './../../aws-exports';

import { setFieldWithID } from '../../slices/userSlice';
import loginImage from '../../img/keys.jpg';

/**
 * login page
 *
 * @returns
 */
export const Login = (): ReactElement => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadAmplifySession() {
      Auth.configure(awsconfig);
      try {
        const data = await Auth.currentSession()
        if (data.isValid()) {
          const token = data.getAccessToken().getJwtToken()
          dispatch(setFieldWithID({ id: 'token', value: token }))

          const payload = data.getAccessToken().decodePayload()
          const groupsArray: string[] = payload['cognito:groups']
          //Redirect user based on group
          if (groupsArray.includes('Admin')) {
            dispatch(setFieldWithID({ id: 'group', value: 'Admin' }))
            navigate('/admin/dashboard');
          } else if (groupsArray.includes('User')) {
            navigate('/');
          }
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    if (!location.state) {
      loadAmplifySession();
    }

  }, [dispatch, navigate, location])

  return (
    <div id="login-page" className="w-full mb-8 md:w-3/4 l:w-7/12 xl:w-1/2 md:rounded-lg mx-auto md:border md:shadow-xl">
      <div className="flex">
        <div className="flex-none md:w-48 lg:w-64 relative">
          <img src={loginImage} alt="WRAR login" className="absolute inset-0 w-full h-full object-cover rounded-l-lg" />
        </div>
        <div className="flex-auto px-12 py-6">
          <LoginForm state={location.state} />
        </div>
      </div>

    </div>
  );
};
