import React, { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getData, setDataFieldWithID } from '../../slices/dataSlice';

export interface OptionProps {
  id?: string;
  label: any;
  subLabel?: string;
  value: any;
  icon?: string;
  covered?: any;
}

interface SelectProps {
  size?: 'form_small' | 'form_medium' | 'form_large';
  label: string;
  id: string;
  required?: boolean;
  errorText?: string;
  onChange?: () => void;
  wrapperClass?: Array<string> | string;
  labelClass?: Array<string>;
  data?: Array<OptionProps>;
  children?: any;
}

export const Select = function ({
  size = 'form_medium',
  label,
  id,
  errorText,
  required = false,
  wrapperClass = [],
  labelClass = [],
  data = [
    { label: 'True', value: true },
    { label: 'False', value: false }
  ],
  ...props
}: SelectProps) {
  const policy = useSelector(getData);
  const policyData = policy.data;
  const currentValue = policyData[id] || '';
  const dispatch = useDispatch();
  let errorMSG: ReactNode;

  (() => {
    if (errorText) {
      errorMSG = <div className="errorText">{errorText}</div>;
      wrapperClass = ['errorWrapper'];
    }
  })();

  return (
    <div className={Array.isArray(wrapperClass) ? wrapperClass.join(' ') : wrapperClass}>
      <label className={['form-label', ...labelClass].join(' ')} htmlFor={id}>
        {label}
      </label>
      <select
        id={id}
        className={['form-select', size].join(' ')}
        value={currentValue}
        onChange={(e) =>
          dispatch(setDataFieldWithID({ id, value: e.target.value }))
        }
        required={required}
        {...props}
      >
        {props.children}
        {data.map((row) => (
          <option key={row.label} value={row.value}>
            {row.label}
          </option>
        ))}
      </select>
      {errorMSG}
    </div>
  );
};
