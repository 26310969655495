import { Footer } from "../Footer/Footer"
import { Header } from "../Header/Header"

export const UserPage = function ({ children }: any) {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Header />
      <main className="container mx-auto">
        {children}
      </main>
      <Footer />
    </div>
  )
}

