import { Admin } from '../Admin';
import { NetworkHelper } from '../../../helpers/networkHelper';
import { ReactElement, useState } from 'react';
import { json2csv } from 'json-2-csv';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { getUser } from '../../../slices/userSlice';
import { Input } from '../../../Components/Input/Input';
import { getData } from '../../../slices/dataSlice';
import { OptionProps, Select } from '../../../Components/Select/Select';

export const Csv = function () {
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useSelector(getUser);
  const { data } = useSelector(getData);
  const { csvDate1, csvDate2, csvPage } = data;

  function renderPage(): ReactElement {
    switch (csvPage) {
      case 'since':
        return SincePage();
      case 'between':
        return BetweenPage();
      case 'within':
        return WithinPage();
      default:
        return SincePage();
    }
  }

  async function submit() {
    setErrorMessage(''); // Remove error message on submit
    let userList: any; // variable used for networkHelper returns
    const tempDate = new Date(); // Variable to check whether any dates are in future

    const date1 = new Date(csvDate1);
    //Check if date is valid 
    if (isNaN(date1.getTime())) {
      setErrorMessage("Please select a date");
      return;
    }

    //Check to see if user selected date in future
    if (date1 > tempDate) {
      if (csvPage === 'between') {
        setErrorMessage('Date 1 cannot be in the future');
      } else {
        setErrorMessage("'Since' date cannot be in the future");
      }
      return;
    }
    //Making sure that date1 is inclusive of the entire day of date 1, starting from 12:00AM. 
    date1.setUTCHours(0, 0, 0, 0);
    const dateStr = date1.toISOString();

    if (csvPage === 'between' || csvPage === 'within') {
      const date2 = new Date(csvDate2);

      //Check to see if date2 is valid
      if (isNaN(date2.getTime())) {
        setErrorMessage("Please select a date");
        return;
      }

      //Check to see if user selected date in future
      if (csvPage === 'between' && date2 > tempDate) {
        setErrorMessage("Date 2 cannot be in the future");
        return;
      }

      if (date1 > date2) {
        setErrorMessage("Start of date range must be before end of date range");
        return;
      }

      date2.setUTCHours(23, 59, 59, 999);
      const dateStr2 = date2.toISOString();

      if (csvPage === 'between') {
        userList = await NetworkHelper.getReportData(token, dateStr, dateStr2, 'between');
      } else if (csvPage === 'within') {
        userList = await NetworkHelper.getReportData(token, dateStr, dateStr2, 'within');
      }
    } else {
      userList = await NetworkHelper.getReportData(token, dateStr, '', 'since');
    }

    const jsonData = userList?.body.result;

    if (jsonData === undefined) {
      setErrorMessage('No users meet criteria.');
      return;
    }

    if (jsonData.Items.length === 0) {
      setErrorMessage('No users meet criteria.');
      return;
    }
    const csv = json2csv(jsonData.Items);

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


    if (csvPage === 'between') {
      const date2 = new Date(csvDate2);
      const dateStr2 = date2.toISOString().split('T')[0];
      FileSaver.saveAs(blob, "OTERR Users Registered Between " + dateStr.split('T')[0] + "&" + dateStr2);
    } else if (csvPage === 'within') {
      FileSaver.saveAs(blob, "OTERR Subscription Renewals Within " + dateStr.split('T')[0]);
    } else { //Default to since as it is possible CSVpage is not set
      FileSaver.saveAs(blob, "OTERR Users Registered Since " + dateStr.split('T')[0]);
    }

  }

  function BetweenPage() {
    return (
      <div id='between-page'>
        <br></br>
        <div>
          <h1>CSV of users registered between:</h1>
        </div>
        <br></br>
        <div className='w-6/12'>
          <Input data-testid="betweenDate" id="csvDate1" label='Enter date 1:' type='date' size='form_small' />
          <br />
          <Input data-testid="betweenDate2" id="csvDate2" label='Enter date 2:' type='date' size='form_small' />
          <br />
          <p className='error text-red-800'>{errorMessage}</p>
          <br />
          <button className='btn btn-primary' onClick={submit}>Download</button>
        </div>
      </div>
    )
  }

  //Since portion of code

  function SincePage() {
    return (
      <div id='since-page'>
        <br></br>
        <div>
          <h1>CSV of users registered since:</h1>
        </div>
        <br></br>
        <div className='w-6/12'>
          <Input data-testid="sinceDate" id="csvDate1" label='Enter "since" date:"' type='date' size='form_small' />
          <br />
          <p className='error text-red-800'>{errorMessage}</p>
          <br />
          <button className='btn btn-primary' onClick={submit}>Download</button>
        </div>
      </div>
    )
  }

  function WithinPage() {
    return (
      <div id='within-page'>
        <br></br>
        <div>
          <h1>CSV of users with subscription renewals within timeframe:</h1>
        </div>
        <br></br>
        <div className='w-6/12'>
          <Input data-testid="withinDate" id="csvDate1" label='Enter time frame for users with upcoming payments:' type='date' size='form_small' />
          <br />
          <Input data-testid="withinDate2" id="csvDate2" label='Enter date 2:' type='date' size='form_small' />
          <p className='error text-red-800'>{errorMessage}</p>
          <br />
          <button className='btn btn-primary' onClick={submit}>Download</button>
        </div>
      </div>
    )
  }

  const selectOptions: OptionProps[] = [
    { value: 'since', label: 'Registered since' },
    { value: 'between', label: 'Registered between' },
    { value: 'within', label: 'Subscription renewal within' }
  ]

  return (
    <div id='csv-page'>
      <Admin>
        <div>
          <Select id="csvPage" label="Select type of CSV report:" data={selectOptions} />
        </div>
        {renderPage()}
        <br></br>
      </Admin>
    </div>
  )
}