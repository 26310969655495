export interface StepOptionProps {
  name: string;
}
export interface StepProps {
  id: string;
  header: string;
  description: string;
  primary?: boolean;
  isActive?: boolean;
  pointer?: string; // assist with check what step user is in
  children?: StepProps[];
  options?: StepOptionProps[];
  nextStep?: string; // overrides nextStep function
}

// 'children' field can hold an array of sub-steps
export const steps: StepProps[] = [
  {
    id: 'signUp', header: 'Step 1', description: 'Sign Up', pointer: '', isActive: true, primary: false
  },
  {
    id: 'register', header: 'Step 2', description: 'Register', pointer: 'Unregistered'
  },
  {
    id: 'payment', header: 'Step 3', description: 'Subscription', pointer: 'Setup Payment | Payment Pending'
  },
  {
    id: 'bor', header: 'Step 4', description: 'Approval', pointer: 'Awaiting Broker Approval | Broker Rejected'
  },
  {
    id: 'sentrilock', header: 'Step 5', description: 'SentriLock', pointer: 'SentriLock Pending'
  },
  {
    id: 'complete', header: 'Step 6', description: 'Active', pointer: 'Active'
  }
];

/**
 * get step row
 *
 * @param key
 * @returns StepProps
 */
export const getStepDetails = (id: string): StepProps | undefined =>
  steps.find((row: StepProps) => row.id === id);

/**
 * get parent of current row
 *
 * @param key
 * @returns StepProps
 */
export const getParent = (
  id: string | undefined,
  stepsFiltered: StepProps[]
): StepProps | undefined => {
  let result = stepsFiltered
    .filter((row) => row.children && row.children.length > 0)
    .find((row) => row.children?.find((child) => child.id === id));
  // if no result check for inner children
  if (!result) {
    stepsFiltered
      .filter((row) => row.children && row.children.length > 0)
      // eslint-disable-next-line consistent-return
      .some((row) => {
        result = row.children
          ?.filter((row2) => row2.children && row2.children.length > 0)
          .find((row2) => row2.children?.find((child) => child.id === id));
        if (result) return true;
        return false;
      });
  }
  return result;
};