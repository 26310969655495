/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setFieldWithID } from '../../slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '../../Components/Typography/Typography';
import { Form } from '../../Components/Form/Form';
import { InputProps } from '../../Components/Input/Input';
import { validationSchemas } from '../../constants/validation';
import Auth from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import { getData, setDataFieldWithID } from '../../slices/dataSlice';
import { NetworkHelper } from '../../helpers/networkHelper';
import loginImage from '../../img/keys.jpg'

/**
 * Sign up page
 *
 * @returns
 */
export const SignUp = (): ReactElement => {
  const user = useSelector(getUser);
  const { data } = useSelector(getData);
  const [formError, setFormError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const formatPhoneNumber = (phone: string) => {
    let phoneNumber = phone.replace('+1', '') // remove country identifier if it exists
    phoneNumber = phoneNumber.replace(/[^\d]/g, ''); // ensure no other non-digit values
    dispatch(setDataFieldWithID({ id: 'phone', value: `+1${phoneNumber}` }));
    return `+1${phoneNumber}`;
  }

  const resetErrorMessages = () => {
    setFormError('');
    setUsernameError('');
    setPhoneError('');
    setPasswordError('');
    setConfirmError('');
  }

  const onSubmit = async (formObj: any) => {
    const validator = validationSchemas['signup'];
    let phoneValue = data['phone'] || '';
    const passwordValue = data['signupPassword'] || '';
    const usernameValue = data['username'] || '';

    const { error } = validator.validate(formObj, {
      abortEarly: false
    });

    if (error) {
      resetErrorMessages();
      for (const row of error.details) {
        if (row.path[0] === 'username') {
          setUsernameError(row.message);
        }
        if (row.path[0] === 'phone') {
          setPhoneError(row.message);
        }
        if (row.path[0] === 'signupPassword') {
          setPasswordError(row.message);
        }
        if (row.path[0] === 'confirmPassword') {
          setConfirmError(row.message);
        }
      }
      return false; // prevent form submission
    } else {
      resetErrorMessages();
      phoneValue = formatPhoneNumber(phoneValue);

      Auth.configure(awsconfig);
      try {
        const user = await Auth.signUp({
          username: usernameValue,
          password: passwordValue,
          attributes: {
            email: data.email,
            name: data.name,
            phone_number: phoneValue,
            // other custom attributes i.e. trreb id
          }
        });
        await NetworkHelper.verifySignUp(user.user.getUsername(), user.userSub, data.name, phoneValue, data.email, data.agencyName, data.agencyID, data.brokerage);
        dispatch(setFieldWithID({ id: 'status', value: 'Unregistered' }));
        navigate('/login', { state: `Your account has been successfully created. Please log in below.` });
        return true;
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case 'UsernameExistsException':
              setUsernameError('Login ID is taken.');
              break;
            case 'InvalidPasswordException':
            case 'InvalidParameterException':
              setFormError('The password must be at least 8 characters.');
              break;
            default:
              setFormError('Something went wrong. Unable to create account at this time.');
          }
        }
        return false;
      }
    }
  }

  function phoneFormatter(value: string): string {
    let digits = value.replace(/\D/g, '').substring(0, 10);
    if (digits.length <= 3) {
      return digits;
    }
    else if (digits.length > 3 && digits.length < 7) {
      return `(${digits.substring(0, 3)}) ${digits.substring(3)}`
    }
    else {
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`
    }
  }

  const inputFields: InputProps[] = [
    {
      type: 'text',
      id: 'name',
      label: 'Name',
      required: true,
      readOnly: true,
      value: data.name ? data.name : ''
    },
    {
      type: 'email',
      id: 'email',
      label: 'Email',
      required: true,
      readOnly: true,
      value: data.email ? data.email : ''
    },
    {
      type: 'text',
      id: 'username',
      label: 'Login ID',
      hint: 'e.g. TRREB_68234',
      required: true,
      errorText: usernameError,
    },
    {
      type: 'text',
      id: 'phone',
      label: 'Mobile Phone Number',
      required: true,
      placeholder: 'e.g. (555) 555-5555',
      hint: 'This field is required for multifactor authentication.',
      maxLength: 16,
      errorText: phoneError,
      formatter: phoneFormatter
    },
    {
      type: 'password',
      id: 'signupPassword',
      label: 'Password',
      required: true,
      hint: "Password must contain at least 8 characters",
      errorText: passwordError
    },
    {
      type: 'password',
      id: 'confirmPassword',
      label: 'Confirm Password',
      required: true,
      errorText: confirmError
    }
  ];
  const params = useParams();
  useEffect(() => {
    if (user.token) {
      navigate('/')
      return
    }
    else if (data.email) {
      return
    }
    async function validateLink() {

      const { encName, encEmail, encUID, encBrokerage, encPass, paramIV, passIV } = params;
      const result = await NetworkHelper.validateSignupLink(encName, encEmail, encUID, encBrokerage, encPass, paramIV, passIV);

      if (result.error) {
        if (result.error.response.body.exists) {
          // User already exists
          navigate('/link-used')
        }
        else {
          // Go to error page
          navigate('/404')
        }
      } else {
        // Add data to slice
        dispatch(setDataFieldWithID({ id: 'name', value: result.user.name }));
        dispatch(setDataFieldWithID({ id: 'email', value: result.user.email }));
        dispatch(setDataFieldWithID({ id: 'agencyName', value: result.user.agencyName }));
        dispatch(setDataFieldWithID({ id: 'agencyID', value: result.user.agencyID }));
        dispatch(setDataFieldWithID({ id: 'brokerage', value: result.user.brokerage }));
      }
    }
    validateLink()
  }, [navigate, user.token, params, dispatch, data.email]);

  return (
    <div id="sign-up-page" className="w-full mb-8 md:w-3/4 l:w-7/12 xl:w-1/2 md:rounded-lg mx-auto md:border md:shadow-xl">
      <div className="flex">
        <div className="flex-none md:w-48 lg:w-64 relative">
          <img src={loginImage} alt="" className="absolute inset-0 w-full h-full object-cover rounded-l-lg" />
        </div>
        <div className="flex-auto px-12 py-6">
          <Typography typeClass={['text-center']} content="Create an account" usage="header" />
          <p>Welcome to OTERR - the platform where you can register for lockbox access to show properties in the Waterloo region.</p>
          <p>Complete the form below to sign up and begin the registration/validation process.</p>
          <p><b>Note:</b> Your home agency has recorded your acceptance to access this application. Ensure you complete this account creation before closing the window to avoid future login issues.</p>
          <Form hideCancel inputFields={inputFields} errorMessage={formError} valuesOnSubmit onSubmit={onSubmit} ></Form>
        </div>
      </div>
    </div>
  );
};
