/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect, useState } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import { Elements, useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from '../../Components/Button/Button';
import './Payment.scss';
import { NetworkHelper } from '../../helpers/networkHelper';
import { getUser } from '../../slices/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alert from "@mui/material/Alert";
import { Loader } from '../../Components/Loader/Loader';
import { ProgressBar } from '../../Components/Progress/ProgressBar';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

/**
 * Payment page
 *
 * @returns ReactElement
 */
export const Payment = (): ReactElement => {
  const [clientSecret, setClientSecret] = useState('');
  const user = useSelector(getUser);

  useEffect(() => {
    if (!clientSecret) {
      setupIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setupIntent = async () => {
    if (user) {
      // create customer and retrieve client secret
      const result = await NetworkHelper.setupStripeIntent(user);
      setClientSecret(result.client_secret);
    }
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  if (!stripePromise || clientSecret === '') return (
    <div className="payment-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
      <PaymentHeader />
    </div>)
  return (
    <div className="payment-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
      <PaymentHeader />
      <Elements key={clientSecret} stripe={stripePromise} options={options}>
        <CheckoutForm setupIntent={setupIntent} user={user} />
      </Elements>
    </div>
  );
};

const PaymentHeader = () => {
  return (
    <>
      <ProgressBar />
      <Typography content="Subscription" usage="header" />
      <div className='text-left'>
        <p>
          The fee for the OTERR service is $60/quarter + HST, and your quarter begins on the date the initial payment is processed. Payment is non-refundable and will be collected at the beginning of each three-month period.
        </p>
        <p>You will not be charged until your application has been approved. (Note: It may take up to two business days to process your application).</p>
        <p><b>Amount due on activation: </b>CA$67.80</p>
        <br />
      </div>
    </>
  )
}

const CheckoutForm = ({ setupIntent, user }: { setupIntent: any, user: any }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState<any>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams && searchParams.get('error')) {
      const error = (searchParams.get('error'));
      if (error) {
        setPaymentResult({ error: { message: error } })
      }
    }
  }, [searchParams])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      // confirm intent
      const confirmSetup: any = await stripe.confirmSetup({
        elements,
        redirect: 'if_required'
      });

      if (confirmSetup.error) {
        // Show error to your customer (for example, payment details incomplete)
        setPaymentResult({ error: { message: confirmSetup.error.message } })
      } else if (user.status === 'Payment Pending') {
        // payment method doesn't update automatically from intent
        // update payment method by passing the confirmed payment method id
        await NetworkHelper.updatePaymentMethod(confirmSetup.setupIntent.payment_method, user.token);

        // Separate process when broker approved but payment failed
        const attempPayment = await NetworkHelper.attemptPayment(user);

        // reset intent if failed
        if (attempPayment.error) {
          setPaymentResult(attempPayment);
          navigate(`/payment?error=${attempPayment.error.message}`);
          setupIntent();
        } else {
          setPaymentResult({ success: 'Payment successful. Returning home.' });
          setTimeout(() => navigate('/'), 4000);
        }
      }
      else {
        // payment method doesn't update automatically from intent
        // update payment method by passing the confirmed payment method id
        await NetworkHelper.updatePaymentMethod(confirmSetup.setupIntent.payment_method, user.token);
        setPaymentResult(confirmSetup);
        await NetworkHelper.sendBrokerEmail(user.id, user.token, '');
        navigate('/');
      }
    } catch (err: any) {
      setPaymentResult({ error: { message: err.message } })
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='relative'>
      <Loader hideText={true} show={!stripe || loading} />
      <form className="payment-form" onSubmit={handleSubmit}>
        {
          paymentResult && (paymentResult.error || paymentResult.success) ?
            <Alert className="my-4" severity={paymentResult.error ? 'error' : 'success'}>{paymentResult.error ? paymentResult.error.message : paymentResult.success || 'Payment method saved successfully'}</Alert> : <></>
        }
        <PaymentElement />
        <Button type="submit" label="Submit" primary buttonClass={["login-button", "btn", "btn-primary", "w-full", "my-4"]} disabled={!stripe || loading} />
      </form>
    </div>
  );
};