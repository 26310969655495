import React from 'react';

interface AnchorProps {
  /**
   * Sets size of anchor text
   */
  size?: 'text_small' | 'text_medium' | 'text_large';
  /**
   * Anchor text content
   */
  label?: string;
  /**
   * Element id
   */
  id?: string;
  /**
   * Classes applied to anchor element
   */
  anchorClass?: Array<string>;
  /**
   * Classes applied to image element
   */
  imgClass?: Array<string>;
  /**
   * Contains tel, link, mailto, etc.
   */
  href: string;
  /** */
  target?: '_self' | '_blank';
  /**
   * Used when anchor is an image
   */
  src?: any;
  /**
   * Alt text when image doesn't render
   */
  alt?: string;
  /**
   * Standard children prop
   */
  children?: any;
}

/**
 * Primary UI component for generating anchor elements
 * @param type of AnchorProps
 * @returns 
 */
export const Anchor = function ({
  size = 'text_medium',
  label,
  target = '_self',
  anchorClass = [],
  imgClass = [],
  src,
  alt = '',
  ...props
}: AnchorProps) {
  return (
    <a target={target} className={[...anchorClass, size].join(' ')} {...props}>
      {' '}
      {label || (
        <img src={src} className={[...imgClass, size].join(' ')} alt={alt} />
      )}{' '}
    </a>
  );
};
