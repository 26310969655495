/* eslint-disable import/no-unresolved */
import './Confirmation.scss'
import { ReactElement, useEffect, useState } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import { Form } from '../../Components/Form/Form';
import { InputProps } from '../../Components/Input/Input';
import { validationSchemas } from '../../constants/validation';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, loadUser } from '../../slices/userSlice';
import { NetworkHelper } from '../../helpers/networkHelper';
import { useNavigate } from 'react-router-dom';
import { getData, setDataFieldWithID } from '../../slices/dataSlice';
import Alert from '@mui/material/Alert';

/**
 * Confirmation page
 *
 * @returns ReactElement
 */
export const Confirmation = (): ReactElement => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user = useSelector(getUser);
  const { data } = useSelector(getData);
  const { loading } = data;
  const [formError, setFormError] = useState('');
  const [resendResult, setResendtResult] = useState<any>();
  const [infoString, setInfoString] = useState('Your Broker of Record has been notified and will need to approve your registration. If you need to update the email address for your Broker or resend the confirmation, submit the form below.');

  useEffect(() => {
    if (user.status === 'Broker Rejected') {
      setInfoString('Your Broker of Record has rejected your previous request. Please contact them and resend the confirmation by submitting the form below.');
    }
    dispatch(setDataFieldWithID({ id: 'brokerEmail', value: user.broker_email }))
  }, [dispatch, user.broker_email, user.status]);

  const resendBORApprovalRequest = async (formObj: any) => {
    try {
      await dispatch(setDataFieldWithID({ id: 'loading', value: true }));
      const validator = validationSchemas['confirmation'];

      const { error } = validator.validate(formObj, {
        abortEarly: false
      });

      if (error) {
        const errorMessage = error.details.map(row => row.message).join(' | ');
        await setFormError(errorMessage);
        setResendtResult(false);
        return false; // prevent form submission
      } else {
        setFormError(' ');
        const resultUser = await NetworkHelper.fetchUserInfo(user.token);
        const updatedStatuses = ['SentriLock Pending', 'Active', 'Broker Rejected', 'Payment Pending']
        // 2 cases to account for : 1: user now active. 2: user rejected, and screen not displaying rejected info yet...
        // if user rejected and screen displaying rejected info, move on to sending email
        if ((updatedStatuses.includes(resultUser.user.status)) &&
          user.status !== resultUser.user.status) {
          const { token } = user;
          dispatch(loadUser({ ...resultUser.user, token }));
          navigate('/');
          return;
        }

        // display errors
        const updateToken = await NetworkHelper.updateBrokerToken(user.id, user.token);
        const sendBrokerEmail = await NetworkHelper.sendBrokerEmail(user.id, user.token, formObj.brokerEmail);
        if ((updateToken && updateToken.error) || (sendBrokerEmail && sendBrokerEmail.error)) {
          setFormError(updateToken.error.message || sendBrokerEmail.error.message);
          setResendtResult(false);
        } else {
          setResendtResult(true);
        }
      }
    } catch (err: any) {
      setFormError(err.message);
    } finally {
      await dispatch(setDataFieldWithID({ id: 'loading', value: false }));
    }
  };

  const inputFields: InputProps[] = [ //& React.HTMLAttributes<HTMLInputElement>[]
    {
      type: 'email',
      id: 'brokerEmail',
      label: 'Broker of Record Email',
      // autoComplete: 'off',
      required: true
    }
  ];

  return (
    <div className="confirmation-page">
      <Typography typeClass={['text-left']} content="Thank you for Registering" usage="header" />
      <p className="text-left">
        {infoString}
      </p>
      <p className="text-left">Note: Resending the confirmation email will invalidate the previous validation links.</p>
      <p className="text-left"><b>Please allow 24 hours for your broker to process the request before attempting to resend.</b></p>
      <Form hideCancel childrenPosition='top' submitLabel='Resend Broker Approval' loading={loading} inputFields={inputFields} errorMessage={formError} valuesOnSubmit onSubmit={resendBORApprovalRequest} >
        {
          resendResult ?
            <Alert className="my-4" severity={resendResult.error ? 'error' : 'success'}>{resendResult.error ? resendResult.error.message : 'Successfully sent email'}</Alert> : <></>
        }
      </Form>
    </div>
  );
};
