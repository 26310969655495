/* eslint-disable import/no-unresolved */
import './Landing.scss'
import { ReactElement, useEffect } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import { Button } from '../../Components/Button/Button';
import { useNavigate } from 'react-router';
import { getUser, loadUser, UserState } from '../../slices/userSlice';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Confirmation } from '../../pages';
import { NetworkHelper } from '../../helpers/networkHelper';
import { Loader } from '../../Components/Loader/Loader';
import { ProgressBar } from '../../Components/Progress/ProgressBar';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

/**
 * Landing page
 *
 * @returns ReactElement
 */
export const Landing = (): ReactElement => {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadUserInfo() {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const { token } = user
      const result = await NetworkHelper.fetchUserInfo(token);
      dispatch(loadUser({ ...result.user, token }));
    }
    loadUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div className="landing-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
      <LandingHeader />
      {renderLink(user, navigate)}
    </div>
  );
};

const renderLink = (user: any, navigate: any) => {
  const handleUpdatePaymentMethodClick = async () => {
    // get checkout session id
    const session: any = await NetworkHelper.stripeCheckoutSession(user);
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkoutResult = await stripe?.redirectToCheckout({
      sessionId: session.id,
    });

    // if checkoutResult.error
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  if ((user.status === 'Awaiting Broker Approval') || (user.status === 'Broker Rejected')) {
    return (
      <Confirmation />
    )
  }
  else if (user.status === 'SentriLock Pending' || user.status === 'Active') {
    return (
      <>
        <br />
        {user.status === 'SentriLock Pending' && <p className='text-left'>
          Your access request has been approved by your Broker of Record and your account is now pending SentriLock approval. WRAR Admins have been notified of your request. Please allow up to 48 hours for this to be processed.
          <br /><br />
          You will receive a SentriLock activation email once approval has been completed.
        </p>}
        {user.status === 'Active' && <p className='text-left'>
          Congratulations, your account has been fully approved. A SentriLock activation email has be sent to the email on file from SentriKey with further instructions.
        </p>}
        <div className='text-center'>
          <UserInfoTable user={user} />
          <Button buttonClass={['mt-5']} primary label="Review Subscription Details" onClick={() => navigate('/payments')} /><br />
          <Button buttonClass={['mt-5']} primary label="Update Payment Method" onClick={handleUpdatePaymentMethodClick} />
        </div>
        <br />
        <div className='text-left'>
          <br />
          <hr />
          <br />
          <p className='font-bold'>
            SentriLock Resources
          </p>
          <p>
            SentriLock has created numerous training videos for SentriKey, and a good place to get started is here. After you learn the basics, you will find numerous other training videos on the SentriLock YouTube channel that goes into greater details on specific functions.
          </p>
          <p className='underline font-bold'>
            SentriLock Support: Canada and Toll Free:
          </p>
          <p className='font-bold'>
            1-877-736-8745 (7 days a week, 8am-12am, EST)
          </p>
          <p className='underline font-bold'>
            Support Resources:
          </p>
          <p>
            <a href='https://www.sentrilock.com/support/' target='_blank' rel="noreferrer">
              Support - SentriLock
            </a>
          </p>
          <p>
            <a href='https://www.sentrilock.com/' target='_blank' rel="noreferrer">
              Real Estate Power at Your Fingertips - SentriLock
            </a>
          </p>
          <p className='underline font-bold'>
            Videos:
          </p>
          <p>
            <span className='font-bold'>How-To Playlist:</span> <a href='https://www.youtube.com/playlist?list=PLQnExu3ZpUIoKnue5cx2YZgHwsU_vSnu6' target='_blank' rel="noreferrer"> How To Videos</a>
          </p>
        </div>

        <br></br>
      </>
    )
  }
  else if (user.status === 'Payment Pending') {

    return (
      <div className='text-center'>
        <Typography typeClass={['text-left']} content="Subscription" usage="header" />
        <p className="text-left">
          Congratulations, your account has been approved, but you're not yet subscribed due to a payment failure.
        </p>
        <div className="flex flex-col md:flex-row lg:w-10/12 mx-auto mb-4">
          <div className="w-full mx-auto mb-4 md:mb-0">

            <UserInfoTable user={user} />
          </div>
        </div>
        <div className="w-full text-left">
          <p>Press the button below to provide new payment details and complete payment.</p>
        </div>
        <Button buttonClass={['mt-5', "btn", "btn-primary", "w-full", "max-w-md", "my-4"]} primary label="Continue to payment" onClick={() => navigate('/payment')} />
      </div>
    )
  }
  else if (user.status === 'Deactivated by Agency') {

    return (
      <>
        <Typography typeClass={['text-left']} content="Deactivated by Agency" usage="header" />
        <p className="text-left">
          Your account was deactivated by the agency. In order to reactivate it, you must complete the registration process again and obtain broker approval.
        </p>
        <Button buttonClass={['mt-5', "btn", "btn-primary", "w-full", "max-w-md", "my-4"]} primary label="Register" onClick={() => navigate('/register')} />
      </>
    )
  }
  else if (user.status === 'Suspended') {
    return (
      <>
        <Typography typeClass={['text-left']} content="Account Suspended" usage="header" />
        <p className="text-left"> Your account has been suspended. Please contact oterr@wrar.ca for further support.</p>
      </>
    )
  }
  else if (user.status === 'Unregistered') {
    return (
      <>
        <Typography typeClass={['text-left']} content="Complete your account" usage="header" />
        <p className="text-left">
          Once you have completed your account information, a confirmation email will be sent to your broker/manager to approve. At this stage your broker/manager will be provided with immediate access to authorize your application and then WRAR staff will be notified to complete the process by validating and approving the information provided. Note it is only at this final stage that you will be charged the service fee.
          <br /><br />
          <b>We strongly encourage you to enroll in this program prior to needing access since it may take up to two business days to process your application once we receive the authorization back from your broker/manager.</b>
          <br /><br />
          Any questions or comments should be sent to <a href="mailto:oterr@wrar.ca">OTERR@WRAR.ca</a>
          <br /><br />
          Please select the “Register” link below to begin the registration process with your account information.
        </p>
        <div className='text-center'>
          <Button buttonClass={['mt-5', "btn", "btn-primary", "w-full", "max-w-md", "my-4"]} primary label="Register" onClick={() => navigate('/register')} />
        </div>
      </>
    )
  }
  else {
    return (
      <div className='relative h-24'>
        <Loader show={true} hideText={true} />
      </div>
    )
  }
}


const LandingHeader = (): ReactElement => {
  return (
    <>
      <div className='text-center'>
        <Typography usage='header' content='OTERR Home Page' />
      </div>
      <div className='text-left'>
        <p>
          Welcome to OTERR, the <b>O</b>ut of <b>T</b>own <b>E</b>lectronic <b>R</b>EALTOR® <b>R</b>egistration service - we're happy to see you. This application is a collaboration between the Waterloo Region Association of REALTORS® (WRAR), the Toronto Regional Real Estate Board and SentriLock. It is designed to eliminate any barriers or frustrations for you and your clients when showing homes that have a SentriLock lockbox.
        </p>
      </div>
      <br />
      <hr />
      <ProgressBar />
    </>
  )
}

const UserInfoTable = ({ user }: { user: UserState }): ReactElement => {
  const { email, name, reco_id, phone_number } = user

  return (
    <table className="table-auto w-full mb-8 text-left">
      <thead>
        <tr>
          <th>Email</th>
          <th>Trade Name</th>
          <th>RECO ID</th>
          <th>Phone Number</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{email}</td>
          <td>{name}</td>
          <td>{reco_id}</td>
          <td>{phone_number}</td>
        </tr>
      </tbody>
    </table>
  )
}