/* eslint-disable import/no-unresolved */
import { Form } from '../../Components/Form/Form';
import { InputProps } from '../../Components/Input/Input';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../slices/dataSlice';
import { Typography } from '../../Components/Typography/Typography';
import { validationSchemas } from '../../constants/validation';
import Auth from '@aws-amplify/auth';
import awsconfig from './../../aws-exports';

export const ForgotPassword = function () {
  const data = useSelector(getData);
  const navigate = useNavigate();
  const usernameValue = data.data['username'] || '';
  const codeValue = data.data['passwordCode'] || '';
  const passwordValue = data.data['newPassword'] || '';
  const [errorMessage, setFormError] = useState("");
  const [displayState, setDisplayState] = useState('username');
  const [codeError, setCodeError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [userError, setUserError] = useState('');

  Auth.configure(awsconfig);

  const resetErrorMessages = () => {
    setFormError('');
    setCodeError('');
    setPasswordError('');
    setConfirmError('');
    setUserError('');
  }

  // Function to handle authentication of login form with cognito using amplify
  const submitUsername = async (formObj: any) => {
    const validator = validationSchemas['username'];
    const { error } = validator.validate(formObj, {
      abortEarly: false
    });

    if (error) {
      resetErrorMessages();
      for (const row of error.details) {
        if (row.path[0] === 'username') {
          setUserError(row.message);
        }
      }
      return false; // prevent form submission
    } else {
      resetErrorMessages();
      try {
        await Auth.forgotPassword(usernameValue);
      } catch (error) {
        console.log("Could not send code to user");
        setFormError('Could not send code.');
      }
      switchForm();
      return true;
    }
  }

  const submitCode = async (formObj: any) => {
    const validator = validationSchemas['resetpassword'];
    const { error } = validator.validate(formObj, {
      abortEarly: false
    });

    if (error) {
      resetErrorMessages();
      for (const row of error.details) {
        if (row.path[0] === 'passwordCode') {
          setCodeError(row.message);
        }
        if (row.path[0] === 'newPassword') {
          setPasswordError(row.message);
        }
        if (row.path[0] === 'confirmNewPassword') {
          setConfirmError(row.message);
        }
      }
      return false; // prevent form submission
    } else {
      resetErrorMessages();
      try {
        await Auth.forgotPasswordSubmit(usernameValue, codeValue, passwordValue);
        navigate('/');
        return true;
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case 'ExpiredCodeException':
              setCodeError('Code has expired. Please try again.');
              break;
            case 'CodeMismatchException':
              setCodeError('Incorrect code. Please try again.');
              break;
            case 'TooManyFailedAttemptsException':
              setCodeError('Too many failed attempts. Please try again later.');
              break;
            default:
              setCodeError('Something went wrong. Please try again');
          }
        }
        return false;
      }
    }
  };

  const inputFields: InputProps[] = [
    {
      type: 'text',
      id: 'passwordCode',
      label: 'Code',
      required: true,
      errorText: codeError
    },
    {
      type: 'password',
      id: 'newPassword',
      label: 'New Password',
      required: true,
      errorText: passwordError
    },
    {
      type: 'password',
      id: 'confirmNewPassword',
      label: 'Confirm Password',
      required: true,
      errorText: confirmError
    }
  ];

  const usernameField: InputProps[] = [
    {
      type: 'text',
      id: 'username',
      label: 'Login ID or Username',
      required: true,
      errorText: userError
    }
  ];

  const switchForm = () => {
    setDisplayState('code');
  };

  function renderForm(): ReactElement {
    switch (displayState) {
      case 'username':
        return <UsernameFormDisplay usernameField={usernameField} submitUsername={submitUsername} errorMessage={errorMessage} navigate={navigate} />;
      case 'code':
        return <CodeFormDisplay inputFields={inputFields} submitCode={submitCode} errorMessage={errorMessage} navigate={navigate} />;
      default:
        return <></>;
    }
  }

  return renderForm();
};


function UsernameFormDisplay({ usernameField, submitUsername, errorMessage, navigate }: { usernameField: InputProps[], submitUsername: (e: React.FormEvent<HTMLFormElement>) => void, errorMessage?: string, navigate: any }) {
  return (
    <div className='mx-auto w-100 md:w-3/4 lg:w-1/2 px-4'>
      <Typography typeClass={['text-left']} content="Forgot Password?" usage="header" />
      <p>Enter in the username associated with your account and we'll send an email with a code to reset your password.</p>
      <Form submitLabel={"Submit"} inputFields={usernameField} valuesOnSubmit onSubmit={submitUsername} errorMessage={errorMessage} cancelLabel={"Cancel"} onCancel={() => navigate('/login')}></Form>
    </div>
  )
}

function CodeFormDisplay({ inputFields, submitCode, errorMessage, navigate }: { inputFields: InputProps[], submitCode: (e: React.FormEvent<HTMLFormElement>) => void, errorMessage?: string, navigate: any }) {
  return (
    <div className='mx-auto w-100 md:w-3/4 lg:w-1/2 px-4'>
      <Typography typeClass={['text-left']} content="Reset Password" usage="header" />
      <p>We have sent a password reset code using the email associated with your account. Please note that the code is only valid for 1 hour.</p>
      <Form submitLabel={"Reset Password"} inputFields={inputFields} valuesOnSubmit onSubmit={submitCode} errorMessage={errorMessage} cancelLabel={"Cancel"} onCancel={() => navigate('/login')}></Form>
    </div>
  )
}