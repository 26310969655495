import { createSlice } from '@reduxjs/toolkit';
import { StoreType } from '../store/store';

export type DataState = { [key: string]: any };

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    data: {}
  },
  reducers: {
    setDataFieldWithID: (state, action) => {
      const { id, value } = action.payload;
      const data = { ...state.data, [id]: value };
      return { ...state, data };
    },
    toggleCollection: (state, action) => {
      const { collectionID, fieldID } = action.payload;
      const currentData: { [key: string]: any } = state.data;
      let collection: string[] = [];

      if (currentData[collectionID]) {
        collection = [...currentData[collectionID]];
      }

      if (collection.includes(fieldID)) {
        const index = collection.indexOf(fieldID);
        collection.splice(index, 1);
      } else {
        collection.push(fieldID);
      }
      const data = { ...state.data, [collectionID]: collection };
      return { ...state, data };
    },
    loadData: (state, action) => {
        return action.payload;
    },
    clearData: () => {
      return { data: {} };
    }
  }
});

export const { setDataFieldWithID, loadData, clearData, toggleCollection } =
  dataSlice.actions;
export const getData = (state: StoreType): DataState => state.data;
export default dataSlice.reducer;
