import { Admin } from '../Admin'
import { useState, useEffect } from 'react'
import { DataTable, DataTableItem } from '../../../Components/DataTable/DataTable'
import { GridColDef } from '@mui/x-data-grid';
import { NetworkHelper } from '../../../helpers/networkHelper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../slices/userSlice';
import { Loader } from '../../../Components/Loader/Loader';

export const Dashboard = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  let [rows, setRows] = useState<DataTableItem[]>([]);
  let navigate = useNavigate();

  const { token } = useSelector(getUser);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Realtor Name', flex: 1 },
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'memberBoard', headerName: 'Board', flex: 1 },
    { field: 'brokerage', headerName: 'Brokerage', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'createdAt', headerName: 'Created At', flex: 1 },
  ];

  async function rowClick(rowData: { [key: string]: any }) {
    const uid = rowData.id;
    navigate('/admin/user/' + uid);
  }

  useEffect(() => {
    async function populateTable() {
      await setLoading(true);
      const userList = await NetworkHelper.getUserList(token);
      const tableList: DataTableItem[] = userList.map(convertData);
      await setRows(tableList);
      await setLoading(false);
    }

    function convertData(data: any) {
      let item: DataTableItem = {
        brokerage: data.brokerage_name,
        id: data.id,
        email: data.email,
        name: data.name,
        username: data.username,
        memberBoard: data.agency_name,
        status: data.status,
        createdAt: data.created_at
      };

      return item;
    }

    populateTable();
  }, [token]);

  return (
    <Admin>
      <Loader show={loading} hideText={true} />
      <DataTable data={rows} columns={columns} sortField='createdAt' sortDirection='desc' onRowClick={rowClick} />
    </Admin>
  )
}
