/* eslint-disable import/no-unresolved */
import { ReactElement } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import { useSelector } from 'react-redux';
import { getData } from '../../slices/dataSlice';

/**
 * Activate page
 *
 * @returns ReactElement
 */
export const BrokerActivateComplete = (): ReactElement => {
  const data = useSelector(getData);
  const result = data.data['result'] || '';
  const message = data.data['message'] || '';
  const subheading = `${message} Thank you for completing this process. You are safe to close the window.`

  return (
    <div className="activate-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
      <Typography typeClass={['text-center']} content='Broker Response Received' usage="header" />
      <hr />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Typography typeClass={['text-center']} content={result} usage="subheader" />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <br />
      <Typography typeClass={['text-center']} content={subheading} usage="subheader" />
    </div>
  );
};