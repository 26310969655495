import React from 'react';

interface TypeProps {
  usage: 'header' | 'subheader';
  content: string;
  typeClass?: Array<string>;
}

export const Typography = function ({
  usage = 'header',
  content = 'Lorem Ipsum',
  typeClass = []
}: TypeProps) {
  if (usage === 'header') {
    return <h1 className={[usage, ...typeClass].join(' ')}>{content}</h1>;
  }
  return <h3 className={[usage, ...typeClass].join(' ')}>{content}</h3>;
};
