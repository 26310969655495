import superagent from 'superagent';
import { Auth } from '@aws-amplify/auth';
import awsconfig from '../aws-exports';
import { clearData, setFieldWithID } from '../slices/userSlice';
import store from '../store/store';

var jwt = require('jwt-decode');

const baseURL = (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api-dev.oterr.ca');

function composeRequestURL(endpoint: string) {
  return `${baseURL}/${endpoint}`;
}

async function refreshTokenIfRequired(token: string) {
  let payload = jwt.jwtDecode(token);

  if ((payload.exp * 1000) < Date.now()) {
    Auth.configure(awsconfig);
    try {
      console.log("REFRESHING TOKEN")
      const data = await Auth.currentSession()
      const freshToken = data.getAccessToken().getJwtToken();
      store.dispatch(setFieldWithID({ id: 'token', value: freshToken }));
      return freshToken
    }
    catch (error) {
      store.dispatch(clearData());
    }
  }
  return token
}

async function adminUserStatusCall(path: string, token: string, id: string) {
  const freshToken = await refreshTokenIfRequired(token);
  try {
    const result = await superagent
      .post(composeRequestURL(path))
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + freshToken)
      .send({ 'id': id });

    return result;
  } catch (error) {
    console.log(error);
  }
}

export const NetworkHelper = {

  fetchUserInfo: async (token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .get(composeRequestURL('fetch-user-data'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({});

      const data = result.body;
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  validateSignupLink: async (encName: string | undefined, encEmail: string | undefined, encUID: string | undefined, encBrokerage: string | undefined, encPass: string | undefined, paramIV: string | undefined, passIV: string | undefined) => {
    try {
      const result = await superagent
        .post(composeRequestURL('validate-signup-link'))
        .set('Content-Type', 'application/json')
        .send({ encryptedName: encName, encryptedEmail: encEmail, encryptedAgencyID: encUID, encryptedBrokerage: encBrokerage, encryptedPassword: encPass, parameterIV: paramIV, passwordIV: passIV });

      const data = result.body;
      console.log(data)
      return data;
    } catch (error) {
      return { error }
    }
  },

  verifySignUp: async (cognitoUsername: string, userSubID: string, name: string, phone: string, email: string, agencyName: string, agencyID: string, brokerage: string) => {
    try {
      const result = await superagent
        .post(composeRequestURL('verify-sign-up'))
        .set('Content-Type', 'application/json')
        .send({ 'userId': cognitoUsername, 'userSub': userSubID, 'name': name, 'phone': phone, 'email': email, 'agencyName': agencyName, 'agencyID': agencyID, 'brokerage': brokerage })
      return result
    } catch (error) {
      console.log(error);
    }
  },

  verifyAdminRegistration: async (token: string, cognitoUsername: string, userSubID: string, name: string, phone: string, email: string, agencyName: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .post(composeRequestURL('verify-admin-registration'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({ 'userId': cognitoUsername, 'userSub': userSubID, 'name': name, 'phone': phone, 'email': email, 'agencyName': agencyName })
      return result
    } catch (error) {
      console.log(error);
    }
  },

  helloWorldTest: async (token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      await superagent
        .get(composeRequestURL('hello-world'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({});
    }
    catch (error) {
      console.log(error)
    }
  },

  saveUserDetails: async (jsonData: any, token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      await superagent
        .post(composeRequestURL('save-user-details'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send(jsonData)
    } catch (error) {
      console.log(error);
    }
  },

  sendBrokerEmail: async (id: string, token: string, email: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      await superagent
        .post(composeRequestURL('send-broker-email'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({ 'id': id, 'email': email })
    } catch (error: any) {
      console.log(error);
      return error.response?.body;
    }
  },

  sendRegistrationToAdmin: async (id: any, token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      await superagent
        .post(composeRequestURL('send-registration-to-admin'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({ 'id': id })
    } catch (error) {
      console.log(error);
    }
  },

  setupStripeIntent: async (user: any = {}) => {
    const freshToken = await refreshTokenIfRequired(user.token);
    try {
      const result: any = await superagent
        .post(composeRequestURL('setup-intent'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({
          email: user.email,
          name: user.name,
          phoneNumber: user.phone
        });

      const data = result.body;
      return data;
    } catch (error) {
      return { error }
    }
  },

  stripeCheckoutSession: async (user: any = {}) => {
    const freshToken = await refreshTokenIfRequired(user.token);
    try {
      const result: any = await superagent
        .post(composeRequestURL('checkout-session'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({
          email: user.email,
          name: user.name,
          phoneNumber: user.phone,
          baseURL: process.env.PUBLIC_URL
        });

      const data = result.body;
      return data;
    } catch (error) {
      return { error }
    }
  },

  completeCheckoutSession: async (sessionID: string = '') => {
    try {
      const result: any = await superagent
        .post(`${baseURL}/update-payment-method`)
        .set('Content-Type', 'application/json')
        .send({
          sessionID
        });

      const data = result.body;
      return data;
    } catch (error) {
      return { error }
    }
  },

  updatePaymentMethod: async (paymentMethod: string = '', token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result: any = await superagent
        .post(`${baseURL}/update-payment-method`)
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({
          paymentMethod
        });

      const data = result.body;
      return data;
    } catch (error) {
      return { error }
    }
  },

  attemptPayment: async (user: any = {}) => {
    const freshToken = await refreshTokenIfRequired(user.token);
    try {
      const result: any = await superagent
        .post(`${baseURL}/attempt-payment`)
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send();

      const data = result.body;
      return data;
    } catch (error: any) {
      return error.response.body;
    }
  },

  getPaymentInfo: async (user: any = {}) => {
    const freshToken = await refreshTokenIfRequired(user.token);
    try {
      const result: any = await superagent
        .get(`${baseURL}/payments-info`)
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send();

      const data = result.body;
      return data;
    } catch (error) {
      return { error }
    }
  },

  updateBrokerToken: async (id: any, token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .post(composeRequestURL('update-broker-token'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .send({ 'id': id })
      return result;
    } catch (error: any) {
      console.log(error);
      return error.response?.body;
    }
  },

  validateBrokerLink: async (key: string | undefined, id: string | undefined, password: string | undefined, paramIV: string | undefined) => {
    try {
      const result = await superagent
        .post(composeRequestURL('validate-broker-link'))
        .set('Content-Type', 'application/json')
        .send({ 'encryptedKey': key, 'encryptedId': id, 'encryptedPassword': password, 'encryptedParamIV': paramIV })
      const data = result.body;
      console.log(data)
      return data;
    } catch (error) {
      console.log(error);
      return { error };
    }
  },

  updateUserPostBroker: async (id: string, result: string, name: string) => {
    try {
      await superagent
        .post(composeRequestURL('update-user-post-broker'))
        .set('Content-Type', 'application/json')
        .send({ 'id': id, 'result': result, 'name': name })
    } catch (error) {
      console.log(error);
    }
  },

  getUserList: async (token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .get(composeRequestURL('get-user-list'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({});

      const data = result.body.result;
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  getAdminList: async (token: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .get(composeRequestURL('get-admin-list'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({});

      const data = result.body.result;
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  getUser: async (token: string, id: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .get(composeRequestURL('get-user'))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({ 'id': id });

      const data = result.body;
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  getReportData: async (token: string, date1: string, date2: string, report: string) => {
    const freshToken = await refreshTokenIfRequired(token);
    try {
      const result = await superagent
        .get(composeRequestURL("get-report-data"))
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + freshToken)
        .query({ 'date1': date1, 'date2': date2, 'reportName': report });

      return result;
    } catch (error) {
      console.log(error);
    }
  },

  suspendUser: async (token: string, id: string) => {
    await adminUserStatusCall('suspend-user', token, id)
  },

  resetUser: async (token: string, id: string) => {
    await adminUserStatusCall('reset-user', token, id)
  },
  confirmSentrilockAccess: async (token: string, id: string) => {
    await adminUserStatusCall('confirm-sentrilock-access', token, id)
  }

}
