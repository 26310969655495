import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepProps, steps as _steps } from '../../constants/nav';
import { Progress } from './Progress';
import { getUser } from '../../slices/userSlice';

interface ProgressBarProps {
  wrapperClass?: Array<string> | string;
}

const RenderChildren = (
  step: StepProps,
  level: number
): any => {
  // const navigationState = useSelector(getNavigation);
  // const { currentStep } = navigationState;

  // let stateClass: ProgressProps['stateClass'] = 'progress_incomplete';
  const key = step.id;

  // const hideStep = (row: StepProps) => {
  //   const stepState = navigationState[row.id];
  //   return !(
  //     level === 1 ||
  //     stepState === 'progress_complete' ||
  //     row.id === currentStep
  //   );
  // };

  // if (currentStep === key) {
  //   stateClass = 'progress_active';
  // } else if (
  //   navigationState[key] &&
  //   navigationState[key] === 'progress_complete'
  // ) {
  //   stateClass = 'progress_complete';
  // }

  if (step.children && step.children?.length > 0) {
    return (
      <Progress
        {...step}
        key={key}
        id={key}
        primary={level === 1}
        disableNavigation={true}
      >
        {step.children?.map((row) => {
          return RenderChildren(row, level + 1);
        })}
      </Progress>
    );
  }
  // const hide = hideStep(step);
  return (
    <Progress
      {...step}
      key={key}
      id={key}
      // stateClass={stateClass}
      primary={level === 1}
      // hide={hide}
      disableNavigation={true}
    />
  );
};

export const ProgressBar = function ({
  wrapperClass = ['text-sm flex flex-row']
}: ProgressBarProps) {
  const user = useSelector(getUser);
  const [steps, setSteps] = useState<StepProps[]>(_steps);

  useEffect(() => {
    if (user) {
      const currentStepIndex = _steps.findIndex(row => row.pointer?.includes(user.status)) || 0;
      setSteps(_steps.map((row, i) => {
        let stateClass = 'progress_incomplete';
        if (currentStepIndex === i) {
          stateClass = 'progress_active';
        } else if (i < currentStepIndex) {
          stateClass = 'progress_complete';
        }
        return { ...row, stateClass }
      }));
    }
  }, [user]);

  return (
    <div key="progressBar" className={Array.isArray(wrapperClass) ? wrapperClass.join(' ') : wrapperClass}>
      {steps.map((step) => RenderChildren(step, 1))}
    </div>
  );
};
