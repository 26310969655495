import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

export interface LoaderProps {
  disableState?: boolean;
  hideText?: boolean;
  headerText?: string;
  subHeaderText?: string;
  children?: React.ReactNode;
  show?: boolean;
  full?: boolean;
}

export const Loader = ({
  disableState = false,
  hideText = false,
  show = false,
  full = false,
  ...props
}: LoaderProps) => {
  if (show) {
    return (
      <div className={[
        (full) ? 'fixed bg-black opacity-75 h-screen top-0 left-0 right-0 bottom-0 w-full' : 'absolute w-full h-full',
        "overflow-hidden flex flex-col items-center justify-center z-50 "
      ].join(' ')}>
        {props.children ? props.children : <Box className="mb-3"><CircularProgress /></Box>}
        {!hideText ? (
          <>
            <h2 className="text-center text-white text-xl font-semibold">{props.headerText ? props.headerText : 'Loading'}</h2>
            <p className="text-center text-white">{props.subHeaderText ? props.subHeaderText : `This may take a few seconds, please don't close this page.`}</p>
          </>
        ) : ''}
      </div>
    )
  } else {
    return <></>
  }
}

