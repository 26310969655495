import React, { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  size?: 'form_small' | 'form_medium' | 'form_large';
  label: string;
  sublabel?: string;
  buttonClass?: string[];
  type?: 'submit' | 'button';
}

export const Button = function ({
  primary = false,
  size = 'form_medium',
  label,
  sublabel,
  buttonClass = [],
  type = 'button',
  ...props
}: ButtonProps) {
  const mode = primary ? 'btn-primary' : 'btn-secondary';
  const handleClick = (e: any) => {
    if (props.onClick) {
      e.preventDefault();
      props.onClick(e);
    }
  }
  return (
    <button
      {...props}
      name={label}
      type={type}
      className={['btn', mode, size, ...buttonClass].join(' ')}
      onClick={handleClick}
    >
      {label} <br />
      <span className="text-sm font-thin">{sublabel}</span>
    </button>
  );
};
