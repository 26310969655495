import kwarLogo from '../../img/wrar_interimlogo.png';
import { Auth } from '@aws-amplify/auth';
import awsconfig from './../../aws-exports';
import { useNavigate } from 'react-router';
import { clearData, getUser } from '../../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../Button/Button';

export const Header = function () {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function logOut() {
    Auth.configure(awsconfig)
    try {
      await Auth.signOut({ global: true })
    }
    catch (error) {
      console.log('error signing out: ', error);
    }
    // Clear token after SignOut as we were seeing some re-renders that refreshed the session
    dispatch(clearData())
    navigate('/login')
  }

  async function logOutClick() {
    await logOut();
  }
  return (
    <header className="text-white mb-8">
      <div className="container mx-auto py-8 px-8 md:px-2">
        {user.token && <Button buttonClass={["btn", "btn-primary", "float-right"]} primary label="Log out" onClick={logOutClick} />}
        <a href="/"><img src={kwarLogo} className="w-60" alt='KWAR Logo' /></a>
      </div>
    </header>
  )
}