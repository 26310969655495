import { ReactElement } from 'react';
import { Typography } from '../../Components/Typography/Typography';

export const BrokerExpired = (): ReactElement => {


  return (
    <div>
      <Typography typeClass={['text-left']} content="Broker link expired" usage="header" />
      <p className='text-left'>Your validation link has expired. Please contact the Realtor that initiated the request and have them re-send the verification email.
      </p>
      <br></br>
      <br></br>
      <p className='text-left'>You can safely close this window.</p>
    </div>
  )
}