/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect, useState } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import './Payments.scss';
import { NetworkHelper } from '../../helpers/networkHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MUIButton } from '@mui/material';
import { Button } from '../../Components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setFieldWithID } from '../../slices/userSlice';
import { Loader } from '../../Components/Loader/Loader';


/**
 * Payment page
 *
 * @returns ReactElement
 */
export const Payments = (): ReactElement => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [updateResult, setUpdateResult] = useState<any>();
  const [paymentsInfo, setPaymentsInfo] = useState<any>();
  const [paymentResult, setPaymentResult] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  let { sessionID } = params;

  useEffect(() => {
    if (sessionID) {
      const completeCheckoutSession = async () => {
        const updateResult = await NetworkHelper.completeCheckoutSession(sessionID);

        if (updateResult.error) {
          setUpdateResult({ error: true, message: updateResult.error.message });
        }
        else {
          setUpdateResult({ error: false, message: 'Successfully saved payment method. Updating records.' });
        }
      }
      completeCheckoutSession();
    } else if (user) {
      const getPaymentInfo = async () => {
        const result = await NetworkHelper.getPaymentInfo(user);

        if (result) {
          setPaymentsInfo(result);
        }
      }
      getPaymentInfo();
    }
  }, [sessionID, user])

  async function cancelSubscription() {
    if (loading === false) {
      setLoading(true)
      await NetworkHelper.resetUser(user.token, user.id)
      navigate('/')
    }
  }


  // if (!stripePromise) return <></>
  if (sessionID) {
    return (
      <div className="payments-page text-center mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
        <Typography content='Subscription Details' usage='header' />
        <div className="payments-content">
          {
            updateResult ?
              <Alert className="my-4" severity={updateResult.error ? 'error' : 'success'}>{updateResult.message}</Alert> : <></>
          }
          <Button buttonClass={['mt-5']} primary label="Return Home" onClick={() => navigate('/')} />
        </div>
      </div>
    );
  } else if (paymentsInfo) {
    const lastPayment = (paymentsInfo.last_payment !== 0 ? new Date(paymentsInfo.last_payment) : new Date()).toLocaleDateString();
    const renewalDate = (paymentsInfo.subscription_renewal_date !== 0 ? new Date(paymentsInfo.subscription_renewal_date) : new Date()).toLocaleDateString();
    const attemptPayment = async (user: any) => {
      setLoading(true);
      const result = await NetworkHelper.attemptPayment(user);
      console.log(`Attempted payment:\n${result}`);
      if (!result.error) {
        dispatch(setFieldWithID({ id: 'failed_invoice_id', value: '' }));
      } else {
        setPaymentResult(result);
      }
      setLoading(false);
    }
    return (
      <div className="payments-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
        {
          paymentResult ?
            <Alert className="my-4" severity={paymentResult.error ? 'error' : 'success'}>{paymentResult.error ? paymentResult.error.message : 'Payment sucessful'}</Alert> : <></>
        }
        <Typography content='Subscription Details' usage='header' />
        <div className="payments-content">
          Subscription status: {paymentsInfo.status} <br />
          Subscription renewal date: {renewalDate} <br />
          Payment date: {lastPayment} <br />
          Card: {paymentsInfo.card} <br /><br />
          {user.failed_invoice_id && user.failed_invoice_id !== '' ? (
            <>
              Subscription payment failed. You can attempt payment again or update the payment method.<br />
              <Button buttonClass={['mt-5']} disabled={loading} primary label="Attempt Payment" onClick={() => attemptPayment(user)} />
            </>
          ) : ''}
        </div>
        <Button buttonClass={['mt-5']} primary label="Cancel Subscription" onClick={() => {
          setLoading(false)
          setConfirmationOpen(true)
        }
        } />
        <br />
        <Button buttonClass={['mt-5']} primary label="Return Home" onClick={() => navigate('/')} />
        <Dialog
          open={confirmationOpen}
          onClose={() => setConfirmationOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Cancel Subscription
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Cancelling your subscription will stop recurring payments for OTERR and deactivate your access to the SentriLock systems. If you wish to reactivate your subscription, you will need to complete the registration and broker approval process again. Do you wish to cancel your subscription?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MUIButton onClick={() => setConfirmationOpen(false)}>Close</MUIButton>
            <MUIButton onClick={cancelSubscription} autoFocus>
              Confirm Cancellation
            </MUIButton>
          </DialogActions>
        </Dialog>
      </div >
    );
  } else {
    return <div className="payments-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4">
      <Typography content='Subscription Details' usage='header' />
      <div className="payments-content relative h-20">
        <Loader hideText={true} show={loading} />
      </div>
      <Button buttonClass={['mt-5']} primary label="Return Home" onClick={() => navigate('/')} />
    </div>
  }
};
