/* eslint-disable import/no-unresolved */
import './Registration.scss';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '../../Components/Typography/Typography';
import { Form } from '../../Components/Form/Form';
import { Input } from '../../Components/Input/Input';
import { validationSchemas } from '../../constants/validation';
import { Checkbox } from '../../Components/Checkbox/Checkbox';
import { NetworkHelper } from '../../helpers/networkHelper';
import Auth from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';
import { getData } from '../../slices/dataSlice';
import { getUser, setFieldWithID } from '../../slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from '../../Components/Progress/ProgressBar';
import store from '../../store/store';

/**
 * registration page
 *
 * @returns
 */
export const Registration = (): ReactElement => {
  const data = useSelector(getData);
  const user = useSelector(getUser);
  const [recoError, setRecoError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  let navigate = useNavigate();

  const resetErrorMessages = () => {
    setRecoError('');
    setNameError('');
    setEmailError('');
  }

  const onSubmit = async () => {
    const validator = validationSchemas['register'];
    const recoIdValue = data.data['recoId'] || '';
    const brokerRecordNameValue = data.data['brokerRecordName'] || '';
    const brokerEmailValue = data.data['brokerEmail'] || '';
    const eulaValue = data.data['eula'] || '';

    const { error } = validator.validate(data.data, {
      abortEarly: false
    });

    if (error) {
      resetErrorMessages();
      for (const row of error.details) {
        if (row.path[0] === 'recoId') {
          setRecoError(row.message);
        }
        if (row.path[0] === 'brokerRecordName') {
          setNameError(row.message);
        }
        if (row.path[0] === 'brokerEmail') {
          setEmailError(row.message);
        }
      }
      return false; // prevent form submission
    } else {
      resetErrorMessages();
      Auth.configure(awsconfig);
      Auth.currentAuthenticatedUser().then(async (data) => {
        const params: any = {
          id: data.attributes.sub,
          recoID: recoIdValue,
          brokerRecordName: brokerRecordNameValue,
          brokerEmail: brokerEmailValue,
          eulaConfirmed: eulaValue
        };
        const token = data.signInUserSession.accessToken.jwtToken;
        await NetworkHelper.saveUserDetails(params, token);
        store.dispatch(setFieldWithID({ id: 'status', value: 'Setup Payment' }))
        navigate('/payment');
        return true;
      });
      return false;
    }
  }

  return (
    <div id="registration-page" className="px-4">
      <div className="mx-auto w-100 md:w-3/4 lg:w-1/2 mb-10">
        <ProgressBar />
        <Typography typeClass={['text-left']} content="Complete your account" usage="header" />
        <p>Please provide the following additional details to complete your account. This information will be used by WRAR administrators as part of validating your access.</p>
        <p><b>Broker of Record Approval Process</b></p>
        <p>Provisioning access through OTERR for properies in the Waterloo region requires verification by your Broker of Record. This verification must be completed before your Sentrilock access can be provided.</p>
        <p>After completing this form as well as the payment details page, your Broker of Record will receive an email requiring them to approve or reject your request.</p>
        <p>Note: Your card will not be charged for the quarterly payment until your Broker has accepted the request</p>
      </div>
      <Form hideCancel onSubmit={onSubmit} >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto w-100 md:w-3/4 lg:w-1/2 mb-5">
          <div>
            <label htmlFor='nameText' className='form-label'>Trade Name</label>
            <p id='nameText'>{user.name}</p>
            <label htmlFor='emailText' className='form-label'>Email</label>
            <p id='emailText'>{user.email}</p>
            <label htmlFor='phoneNumberText' className='form-label'>Phone</label>
            <p id='phoneNumberText'>{user.phone_number}</p>
            <label htmlFor='associationNameText' className='form-label'>Association Name</label>
            <p id='associationNameText'>{user.agency_name}</p>
            <label htmlFor='brokerageNameText' className='form-label'>Brokerage Name</label>
            <p id='brokerageNameText'>{user.brokerage_name}</p>
          </div>
          <div>
            <Input type="text" id="recoId" label="RECO ID" required errorText={recoError} value={user.reco_id} />
            <Input type="text" id="brokerRecordName" label="Broker of Record Name" required errorText={nameError} value={user.broker_record_name} />
            <Input type="text" id="brokerEmail" label="Broker of Record Email" required errorText={emailError} value={user.broker_email} />
          </div>
        </div>
        <div className="mx-auto w-100 md:w-3/4 lg:w-1/2 eula-container mb-10">
          <iframe title='Realtor Lockbox EULA' className='w-full h-full border' src="/kwar-lockbock-access-agreement.html" />
          <a href='/WRAR - Lockbox Access Agreement with Realtors.pdf' target='_blank'>View PDF Version</a>
        </div>
        <Checkbox id="eula" message="I agree to the terms of the software license agreement." wrapperClass={['mt-5', 'mb-5', 'justify-center', 'block', 'text-center']} required />
      </Form>
    </div>
  );
};