/* eslint-disable import/no-unresolved */
import './BrokerActivate.scss'
import { ReactElement, useEffect, useState } from 'react';
import { Typography } from '../../Components/Typography/Typography';
import { Form } from '../../Components/Form/Form';
import { Checkbox } from '../../Components/Checkbox/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { NetworkHelper } from '../../helpers/networkHelper';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, loadUser } from '../../slices/userSlice';
import { getData, setDataFieldWithID } from '../../slices/dataSlice';
import { Input } from '../../Components/Input/Input';
import { Loader } from '../../Components/Loader/Loader';

/**
 * Activate page
 *
 * @returns ReactElement
 */
export const BrokerActivate = (): ReactElement => {
  let navigate = useNavigate();
  const user = useSelector(getUser);
  const data = useSelector(getData);
  const dispatch = useDispatch();
  const [formResult, setFormResult] = useState('');
  const [nameMessage, setNameMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formObj: any) => {
    if (!loading) {
      setLoading(true)
      const nameValue = data.data['broker_record_name'] || '';

      if (!nameValue) {
        setNameMessage('Please fill out this field.');
        setLoading(false)
      }

      if (nameValue) {
        setNameMessage('');
        setFormResult(`${user.name} has been Accepted`);
        await NetworkHelper.updateUserPostBroker(user.id, 'accepted', nameValue);
        // Add data to slice for next page
        dispatch(setDataFieldWithID({ id: 'result', value: `${user.name} has been Accepted` }));
        dispatch(setDataFieldWithID({ id: 'message', value: `` }));
        navigate('/activate/complete');
      }
    }
  }

  const onCancel = async (formObj: any) => {
    if (!loading) {
      setLoading(true)

      const nameValue = data.data['broker_record_name'] || '';

      if (!nameValue) {
        setNameMessage('Please fill out this field.');
        setLoading(false)
      }

      if (nameValue) {
        setFormResult(`${user.name} has been Rejected`)
        await NetworkHelper.updateUserPostBroker(user.id, 'rejected', nameValue);
        // Add data to slice for next page
        dispatch(setDataFieldWithID({ id: 'result', value: `${user.name} has been Rejected` }));
        dispatch(setDataFieldWithID({ id: 'message', value: `The Realtor has been notified of this action.` }));
        navigate('/activate/complete');
      }
    }
  }

  const params = useParams();
  useEffect(() => {
    async function validateLink() {
      const result = await NetworkHelper.validateBrokerLink(params.encToken, params.encUserID, params.encPass, params.paramIV);
      if (result.error) {
        // Go to error page
        navigate('/expired')
      } else {
        dispatch(loadUser({ ...result.user }));
        dispatch(setDataFieldWithID({ id: 'broker_record_name', value: result.user.broker_record_name }));
      }
    }

    validateLink()
  }, [params, navigate, dispatch]);

  return (
    <div className="activate-page mx-auto w-100 md:w-3/4 lg:w-1/2 px-4 relative">
      <Typography typeClass={['text-left']} content="Verify Realtor Access Request" usage="header" />
      <Typography typeClass={['text-left']} content={formResult} usage="subheader" />
      <p className="text-left">
        Greetings,
        <br /><br />
        Your REALTOR®, {user.name} has applied to gain access to the <b>O</b>ut of <b>T</b>own <b>E</b>lectronic <b>R</b>EALTOR® <b>R</b>egistration (OTERR) service provided through the Waterloo Region Association of REALTORS® (WRAR) electronic lockbox program. This program will allow {user.name} to access the WRAR (and some other reciprocal boards) SentriLock lockboxes using the SentriKey application (the same application used by WRAR members).
      </p>
      <hr />
      <br />
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <p><b>User Details</b></p>
          <p>Name: {user.name}</p>
          <p>Agency Name: {user.agency_name}</p>
          <p>Email: {user.email}</p>
          <p>Phone: {user.phone_number}</p>
          <p>RECO ID: {user.reco_id}</p>
        </div>
        <div>
          <p><b>Broker Details</b></p>
          <p>Brokerage Name: {user.brokerage_name}</p>
          <p>Broker of Record Email: {user.broker_email}</p>
          <form className="flex-auto space-y-4 pb-6 w-3/4">
            <Input type="text" id="broker_record_name" label="Broker of Record Name" errorText={nameMessage} required />
          </form>
        </div>
      </div>
      <hr />
      <br />
      <p className="text-left">
        In order to grant access to the secure SentriLock environment, your brokerage approval is required for each individual applicant. Please acknowledge and approve this application by reading and then selecting “Accept EULA” below.
      </p>
      <p className="text-left">
        Once you complete this, the application will be returned to WRAR for processing..
        <br /><br />
        Any questions or comments can be sent to <a href="mailto:oterr@wrar.ca">OTERR@WRAR.ca</a>
      </p>
      <div className="md:pl-4 eula-container mb-10">
        <iframe title='Brokerage EULA' className='w-full h-full border' src="/kwar-grant-of-permission.html" />
        <a href='/WRAR - Grant of Permission Agreement (Brokerage).pdf' target='_blank'>View PDF Version</a>
      </div>
      <Form submitLabel='Accept' cancelLabel='Reject' valuesOnSubmit onSubmit={onSubmit} onCancel={onCancel}>
        <Checkbox id="eula" message="Accept EULA" wrapperClass={['mt-5', 'mb-7']} required />
        {
          loading ?
            <div className='relative h-16'><Loader show={loading} hideText={true} /></div> : <></>
        }
      </Form>

    </div>
  );
};
