import './Form.scss';
import { Button } from '../Button/Button';
import { Input, InputProps } from '../Input/Input';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Loader } from '../Loader/Loader';

export interface FormProps {
  onSubmit?: any;
  valuesOnSubmit?: boolean;
  hideCancel?: boolean;
  onCancel?: any;
  submitLabel?: string;
  cancelLabel?: string;
  inputFields?: InputProps[];
  loading?: boolean;
  children?: React.ReactNode;
  errorMessage?: any;
  childrenPosition?: 'top' | 'bottom';
}

export const Form = function ({ hideCancel = false, childrenPosition = 'bottom', loading = false, ...props }: FormProps) {
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useState(props.inputFields);
  const [error, setError] = useState('');
  const Error = () => <div className="base-form-error">{error}</div>

  // submit handler return key value pairs if valueOnSubmit is true
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (props.onSubmit) {
      if (props.valuesOnSubmit) {
        let formObject: any = {};
        Object.keys(e.target.elements).forEach((key: any) => {
          const row = e.target.elements[key];
          if (row.name.length > 0) formObject[row.name] = row.value;
        });
        props.onSubmit(formObject);
      } else {
        props.onSubmit(e);
      }
    }
  };

  // Reset input fields if there's been a change
  useEffect(() => {
    if (props.inputFields) {
      setInputFields(props.inputFields);
    }

    if (props.errorMessage) {
      setError(props.errorMessage);
    }
  }, [props.inputFields, props.errorMessage]);

  return (
    <div className='relative'>
      <Loader show={loading} hideText={true} />
      <form className="base-form" onSubmit={handleSubmit}>
        {(error.trim() !== '') ? <Error /> : ''}

        {renderInput(props, inputFields, childrenPosition)}

        {props.onSubmit ? <Button type="submit" label={props.submitLabel ? props.submitLabel : 'Submit'} disabled={loading} primary buttonClass={["base-form-button", "w-full", "my-4"]} /> : ''}
        {!hideCancel ? <Button label={props.cancelLabel ? props.cancelLabel : 'Cancel'} buttonClass={["base-form-button", "w-full", "my-4"]} disabled={loading} onClick={(e) => props.onCancel ? props.onCancel(e) : navigate('/')} /> : ''}
      </form>
    </div>
  );
};

const renderInput = (props: FormProps, inputFields: any, childrenPosition: string) => {
  if (props.inputFields) {
    return (
      <>
        {(childrenPosition === 'top') ? props.children : ''}
        {inputFields.map((input: InputProps) => <Input key={input.id} wrapperClass={[...input.wrapperClass || [], 'base-form-group']} {...input} />)}
        {(childrenPosition === 'bottom') ? props.children : ''}
      </>
    );
  }
  else if (props.children) {
    return props.children;
  }
  else return <><span>Form</span></>
}
