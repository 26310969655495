import React from 'react';

import { Social } from '../Social/Social';

export const Footer = () => {
    return (
        <footer className="text-white mt-auto">
            <div className="bg-kwargray-dark">
                <div className="container mx-auto py-4 md:py-20 px-2">
                    <div className="grid md:grid-cols-3 gap-4">
                        <Social />
                        <div className="text-center text-sm text-kwargray-light md:px-16 pb-8 md:pb-36 md:border-l border-kwargray-lighter">
                            A REALTOR® can help ensure success for the largest transaction of your life.<br /> Live with NO REGRETS.
                        </div>
                        <div className="text-left text-sm text-kwargray-light md:px-16 md:pb-36 md:border-l border-kwargray-lighter">
                            540 Riverbend Drive <br />
                            Kitchener, ON N2K 3S2 <br />
                            (519) 576-1400 <br />
                            <a href="mailto:oterr@wrar.ca" className="text-white">oterr@wrar.ca</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-kwargray">
                <div className="container mx-auto py-8 px-2 text-kwargray-light text-sm text-center">
                    Copyright &copy; - Waterloo Region Association of REALTORS&reg;
                </div>
            </div>
        </footer>
    )
}

