import Joi from 'joi';

function defaultRequiredMessages(id: string) {
  return {
    'any.required': `Please complete this field`,
    'string.empty': `Please complete this field`
  };
}

const homeSchema = Joi.object({}).options({ allowUnknown: true });

const registrationSchema = Joi.object({
  recoId: Joi.string().min(6)
    .required()
    .messages({
      ...defaultRequiredMessages('recoId'),
      'string.min': 'RECO ID must be at least 6 characters.'
    }),
  brokerRecordName: Joi.string().trim().min(1)
    .required()
    .messages({ ...defaultRequiredMessages('brokerRecordName') }),
  brokerEmail: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .messages({
      ...defaultRequiredMessages('email'),
      'string.email': 'Incorrect email format ie. email@email.ca'
    }),
}).options({ allowUnknown: true });

const signUpSchema = Joi.object({
  name: Joi.string().trim().min(1)
    .required()
    .messages({ ...defaultRequiredMessages('name') }),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .messages({
      ...defaultRequiredMessages('email'),
      'string.email': 'Incorrect email format'
    }),
  username: Joi.string().min(1)
    .regex(/^[a-zA-Z0-9._-]+$/) // Only alphanumeric, ., -, and _ are acceptable username characters
    .required()
    .messages({
      ...defaultRequiredMessages('username'),
      'string.pattern.base': 'Login ID can only contain numbers, letters, hyphens, underscores, or periods.',
    }),
  phone: Joi.string().trim().min(10)
    .required()
    .messages({
      ...defaultRequiredMessages('phone'),
      'string.min': 'Incorrect phone format'
    }),
  signupPassword: Joi.string().min(8)
    .required()
    .messages({
      ...defaultRequiredMessages('signupPassword'),
      'string.min': 'Invalid password length',
    }),
  confirmPassword: Joi.any().equal(Joi.ref('signupPassword'))
    .required()
    .label('Password')
    .messages({ 'any.only': '{{#label}} does not match' })
}).options({ allowUnknown: true });

const confirmationSchema = Joi.object({
  brokerEmail: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .messages({
      ...defaultRequiredMessages('email'),
      'string.email': 'Incorrect email format'
    })
}).options({ allowUnknown: true });

const resetpasswordSchema = Joi.object({
  passwordCode: Joi.string().min(6)
    .regex(/^[0-9]+$/) // Only numbers
    .required()
    .messages({
      ...defaultRequiredMessages('passwordCode'),
      'string.pattern.base': 'Code can only contains numbers',
    }),
  newPassword: Joi.string().min(8)
    .required()
    .messages({
      ...defaultRequiredMessages('newPassword'),
      'string.min': 'Password must contain at least 8 characters',
    }),
  confirmNewPassword: Joi.any().equal(Joi.ref('newPassword'))
    .required()
    .label('Password')
    .messages({ 'any.only': 'Passwords do not match' })
}).options({ allowUnknown: true });

const usernameSchema = Joi.object({
  username: Joi.string().min(1)
    .required()
    .messages({ ...defaultRequiredMessages('username') })
}).options({ allowUnknown: true });

// const step9Schema = Joi.object({
//   addressOne: Joi.string()
//     .required()
//     .messages({ ...defaultRequiredMessages('addressOne') }),
//   city: Joi.string()
//     .required()
//     .messages({ ...defaultRequiredMessages('city') }),
//   province: Joi.string()
//     .required()
//     .messages({ ...defaultRequiredMessages('province') }),
//   postalCode: joiPostalCode
//     .string()
//     .postalCode('CA')
//     .required()
//     .messages({
//       ...defaultRequiredMessages('postalCode'),
//       'postalCode.invalid': 'postalCode:Incorrect format - A1A A1A'
//     }),
//   country: Joi.string()
//     .required()
//     .messages({ ...defaultRequiredMessages('country') }),
//   phoneNumber: Joi.string()
//     .required()
//     .messages({ ...defaultRequiredMessages('phoneNumber') })
// }).options({ allowUnknown: true });

export const validationSchemas: { [key: string]: Joi.ObjectSchema } = {
  home: homeSchema,
  signup: signUpSchema,
  register: registrationSchema,
  confirmation: confirmationSchema,
  resetpassword: resetpasswordSchema,
  username: usernameSchema
};

export function parseErrorText(errorString: string) {
  const returnObject: { [key: string]: string } = {};

  const fieldErrors: string[] = errorString
    .replace('ValidationError: ', '')
    .split('.');
  fieldErrors.forEach((errorText) => {
    const components = errorText.split(':');
    const key = components[0].trim();
    const value = components[1].trim();
    returnObject[key] = value;
  });
  return returnObject;
}
