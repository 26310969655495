import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setFieldWithID } from '../../slices/userSlice';
import Auth from '@aws-amplify/auth';
import awsconfig from './../../aws-exports';
import { Divider, Tooltip } from '@mui/material';


export const MainNavItems = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function logOut() {
    Auth.configure(awsconfig)
    await Auth.signOut()

    // Clear token after SignOut as we were seeing some re-renders that refreshed the session
    dispatch(setFieldWithID({ id: 'token', value: '' }))

    navigate('/login')
  }

  return (
    <div>
      <br />
      <ListItem data-testid="dashboard-link" button onClick={() => navigate('/admin/dashboard')}>
        <ListItemIcon>
          <Tooltip title="Dashboard">
            <DashboardIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <Divider />
      <ListItem data-testid="admins-link" button onClick={() => navigate('/admin/admins')}>
        <ListItemIcon>
          <Tooltip title="Admin dashboard">
            <PeopleIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Admins" />
      </ListItem>
      <ListItem data-testid="create-link" button onClick={() => navigate('/admin/signup')}>
        <ListItemIcon>
          <Tooltip title="Create admin">
            <AddIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Create New Admin" />
      </ListItem>
      <Divider />
      <ListItem data-testid="csv-link" button onClick={() => navigate('/admin/csv')}>
        <ListItemIcon>
          <Tooltip title="CSV">
            <SummarizeIcon />
          </Tooltip>
        </ListItemIcon >
        <ListItemText primary="CSV Reports" />
      </ListItem>
      <Divider />
      <ListItem data-testid="logout-link" button onClick={() => logOut()}>
        <ListItemIcon>
          <Tooltip title="Logout">
            <LogoutIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </div>
  )


};